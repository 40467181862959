import React, { useState, useEffect } from "react";
import Footer from "../components/Footer";
import { motion, useAnimation } from "framer-motion";
import { BsTelephonePlus, BsGeoAlt, BsClock } from "react-icons/bs";
import { MdOutlineEmail } from "react-icons/md";
import { FaArrowRight, FaPaperPlane, FaWhatsapp } from "react-icons/fa";
import "../styles/contact.css";

const Contact = () => {
  // Form state
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: ""
  });
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formError, setFormError] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  // Check for mobile view
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Animation variants
  const fadeIn = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: { duration: 0.6 }
    }
  };
  
  const staggerContainer = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: isMobile ? 0.15 : 0.2
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Form validation
    if (!formData.name || !formData.email || !formData.message) {
      setFormError(true);
      setTimeout(() => setFormError(false), 3000);
      return;
    }
    
    // Here you would typically send the form data to your backend
    console.log("Form submitted:", formData);
    
    // Show success message and reset form
    setFormSubmitted(true);
    setFormData({
      name: "",
      email: "",
      subject: "",
      message: ""
    });
    
    setTimeout(() => setFormSubmitted(false), 5000);
  };

  // WhatsApp direct contact
  const whatsappNumber = "+2348137816749";
  const openWhatsApp = () => {
    window.open(`https://wa.me/${whatsappNumber}?text=Hello, I'm reaching out from the DON'T BRAG website.`, '_blank');
  };

  return (
    <div className="contact-page">
      {/* Hero Section */}
      <section className="contact-hero">
        <div className="hero-overlay"></div>
        <motion.div 
          className="contact-hero-content"
          initial={{ opacity: 0, y: 30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <div className="section-badge">Get In Touch</div>
          <h1>Contact Us</h1>
          <p>
            We're here to help and answer any questions you might have. 
            We look forward to hearing from you.
          </p>
          {isMobile && (
            <motion.button 
              className="mobile-whatsapp-btn"
              onClick={openWhatsApp}
              whileTap={{ scale: 0.95 }}
            >
              <FaWhatsapp /> Chat on WhatsApp
            </motion.button>
          )}
        </motion.div>
      </section>

      {/* Contact Information Cards */}
      <section className="contact-info-section">
        <div className="contact-container">
          <motion.div 
            className="contact-cards"
            variants={staggerContainer}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: isMobile ? 0.1 : 0.3 }}
          >
            <motion.div 
              className="contact-card" 
              variants={fadeIn}
              whileTap={isMobile ? { scale: 0.98 } : {}}
            >
              <div className="card-icon">
                <BsTelephonePlus />
              </div>
              <h3>Call Us</h3>
              <p>+234 813 781 6749</p>
              <p className="card-subtitle">Mon-Fri, 9am-5pm</p>
            </motion.div>

            <motion.div 
              className="contact-card" 
              variants={fadeIn}
              whileTap={isMobile ? { scale: 0.98 } : {}}
            >
              <div className="card-icon">
                <MdOutlineEmail />
              </div>
              <h3>Email Us</h3>
              <p>dontbrag@gmail.com</p>
              <p className="card-subtitle">We'll respond within 24 hours</p>
            </motion.div>

            <motion.div 
              className="contact-card" 
              variants={fadeIn}
              whileTap={isMobile ? { scale: 0.98 } : {}}
            >
              <div className="card-icon">
                <BsGeoAlt />
              </div>
              <h3>Visit Us</h3>
              <p>Lagos, Nigeria</p>
              <p className="card-subtitle">By appointment only</p>
            </motion.div>
          </motion.div>
        </div>
      </section>

      {/* Contact Form Section */}
      <section className="contact-form-section">
        <div className="contact-container">
          <motion.div 
            className="form-content-wrapper"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8 }}
          >
            <div className="form-content">
              <div className="section-badge">Send a Message</div>
              <h2>How Can We Help?</h2>
              <p>
                Fill out the form below, and our team will get back to you as soon as possible.
                We value your feedback and inquiries.
              </p>
              
              {!isMobile && (
                <div className="brand-quote">
                  <p>
                    <em>"At DON'T BRAG, we believe that true style speaks for itself. 
                    We embrace the beauty of understatement and the power of authenticity."</em>
                  </p>
                </div>
              )}
            </div>

            <div className="form-container">
              {formSubmitted && (
                <div className="form-success">
                  <FaPaperPlane />
                  <h3>Message Sent!</h3>
                  <p>Thank you for reaching out. We'll be in touch soon.</p>
                </div>
              )}
              
              {!formSubmitted && (
                <form onSubmit={handleSubmit} className="contact-form">
                  {formError && (
                    <div className="form-error-message">
                      Please fill out all required fields.
                    </div>
                  )}
                  
                  <div className="form-row">
                    <div className="form-group">
                      <label htmlFor="name">Your Name *</label>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                        placeholder="Enter your name"
                      />
                    </div>
                    
                    <div className="form-group">
                      <label htmlFor="email">Your Email *</label>
                      <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                        placeholder="Enter your email"
                      />
                    </div>
                  </div>
                  
                  <div className="form-group">
                    <label htmlFor="subject">Subject</label>
                    <input
                      type="text"
                      id="subject"
                      name="subject"
                      value={formData.subject}
                      onChange={handleChange}
                      placeholder="What is this regarding?"
                    />
                  </div>
                  
                  <div className="form-group">
                    <label htmlFor="message">Your Message *</label>
                    <textarea
                      id="message"
                      name="message"
                      rows={isMobile ? "4" : "5"}
                      value={formData.message}
                      onChange={handleChange}
                      required
                      placeholder="How can we help you?"
                    ></textarea>
                  </div>
                  
                  <motion.button 
                    type="submit" 
                    className="submit-button"
                    whileTap={{ scale: 0.98 }}
                  >
                    Send Message <FaArrowRight />
                  </motion.button>
                </form>
              )}
            </div>
          </motion.div>
        </div>
      </section>

      {/* FAQ Section - Simplified for mobile */}
      <section className="faq-section">
        <div className="contact-container">
          <motion.div 
            className="faq-content"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6 }}
          >
            <div className="section-badge">Common Questions</div>
            <h2>Frequently Asked Questions</h2>
            
            <div className="faq-grid">
              {/* Show fewer FAQs on mobile */}
              <motion.div 
                className="faq-item"
                whileTap={isMobile ? { scale: 0.98 } : {}}
              >
                <h3>What are your shipping times?</h3>
                <p>We typically process orders within 1-2 business days. Shipping times vary by location, but generally take 3-7 business days within Nigeria.</p>
              </motion.div>
              
              <motion.div 
                className="faq-item"
                whileTap={isMobile ? { scale: 0.98 } : {}}
              >
                <h3>Do you offer international shipping?</h3>
                <p>Yes, we ship worldwide. International orders typically take 7-14 business days to arrive, depending on the destination.</p>
              </motion.div>
              
              {(!isMobile || window.innerWidth > 480) && (
                <>
                  <motion.div 
                    className="faq-item"
                    whileTap={isMobile ? { scale: 0.98 } : {}}
                  >
                    <h3>What is your return policy?</h3>
                    <p>We accept returns within 14 days of delivery. Items must be unworn, unwashed, and with all original tags attached.</p>
                  </motion.div>
                  
                  <motion.div 
                    className="faq-item"
                    whileTap={isMobile ? { scale: 0.98 } : {}}
                  >
                    <h3>How can I track my order?</h3>
                    <p>Once your order ships, you'll receive a tracking number via email that you can use to monitor your delivery status.</p>
                  </motion.div>
                </>
              )}
            </div>
          </motion.div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default Contact;
