/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useEffect, useState } from "react";
import { useCart } from "../components/Cart/cartContext";
import { PaystackButton } from "react-paystack";
import "../styles/checkout.css";
import { MdOutlineArrowDropDown } from "react-icons/md";
import { Country, State } from "country-state-city";
import Payment from "../assets/image/payment.png";
import PaymentImg from "../assets/image/newpaycard.png";
import PaymentApproved from "../components/Receipt/receipt";
import emailjs from "@emailjs/browser";
import PayPalButtonWrapper from "../components/PayPalButtonWrapper";
import StripeButtonWrapper from '../components/StripeButtonWrapper';

const Checkout = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [visible, setVisible] = useState(false);
  const [bankBtn, setBankBtn] = useState(false);
  const { cart, setCart, selectedSize } = useCart();
  const [userDetails, setUserDetails] = useState({
    email: "",
    address: "",
    phoneNumber: "",
    firstName: "",
    lastName: "",
  });
  const [paymentSuccessful, setPaymentSuccessful] = useState(false);
  const [showSummary, setShowSummary] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [showPaymentModal, setShowPaymentModal] = useState(true);
  const [showBankModal, setShowBankModal] = useState(false);
  const [selectedPaymentOption, setSelectedPaymentOption] = useState("");
  const [shippingFee, setShippingFee] = useState(0);
  const [postalCode, setPostalCode] = useState("");
  const [showConfirmPaymentModal, setShowConfirmPaymentModal] = useState(false);
  const [showPaypalModal, setShowPaypalModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showStripeModal, setShowStripeModal] = useState(false);

  const EMAILJS_PUBLIC_KEY = "0y_GSPNF4uGZPoiuw";
  const EMAILJS_SERVICE_ID = "service_ejpmyw3";
  const TEMPLATE_ID_BUSINESS = "template_6jhi5py";
  const TEMPLATE_ID_CUSTOMER = "template_eaqoik3";

  useState("no");

  const ConfirmPaymentModal = ({ onClose, onPaymentMade }) => {
    return (
      <div className="paymentOverlay">
        <div className="Confirmmodal">
          <h2>Bank Transfer Instructions</h2>
          <p>
            Please make a bank transfer to the following account:
            <br />
            Name: DON-T BRAG LUXURY
            <br />
            Account Number: 8137816749
            <br />
            Bank Name: MONIEPOINT MFB
          </p>
          <p>
            After making the payment, please share the receipt via WhatsApp to
            +234 813 7816 749
          </p>
          <div className="btnModaldiv">
            <button onClick={onPaymentMade} className="btnModal">
              I've Made the Payment
            </button>
            <button onClick={onClose} className="btnModal">
              Close
            </button>
          </div>
        </div>
      </div>
    );
  };

  const handleShowModal = () => {
    setVisible(!visible);
    setShowPaymentModal(true);
    setShowBankModal(false);
    setSelectedPaymentOption("paystack");
  };

  const handleBankModal = () => {
    setBankBtn(true);
    setShowBankModal(true);
    setShowPaymentModal(false);
    setSelectedPaymentOption("bankTransfer");
    console.log("Selected Payment Option:", "bankTransfer");
  };

  const handlePaypalModal = () => {
    // Check if required fields are filled
    const requiredFields = [
      "firstName",
      "lastName",
      "email",
      "phoneNumber",
      "address",
    ];
    
    const missingFields = requiredFields.filter(
      (field) => !userDetails[field]
    );
    
    if (missingFields.length > 0) {
      alert("Please fill in all required fields before proceeding to payment.");
      return;
    }
    
    // If all required fields are filled, proceed with PayPal
    setSelectedPaymentOption("paypal");
    setShowPaypalModal(true);
  };

  const handleStripeModal = () => {
    // Check if required fields are filled
    const requiredFields = [
      "firstName",
      "lastName",
      "email",
      "phoneNumber",
      "address",
    ];
    
    const missingFields = requiredFields.filter(
      (field) => !userDetails[field]
    );
    
    if (missingFields.length > 0) {
      alert("Please fill in all required fields before proceeding to payment.");
      return;
    }
    
    // If all required fields are filled, proceed with Stripe
    setSelectedPaymentOption("stripe");
    setShowStripeModal(true);
    setShowPaypalModal(false);
    setShowBankModal(false);
    setShowPaymentModal(false);
  };

  useEffect(() => {
    if (selectedCountry === "NG") {
      setShippingFee(10);
    } else if (selectedCountry) {
      setShippingFee(80);
    } else {
      setShippingFee(0);
    }
  }, [selectedCountry]);

  const subtotal = cart.reduce(
    (acc, curr) => acc + curr.product.price * curr.quantity,
    0
  );
  const total = subtotal + shippingFee;

  const sendOrderDetails = async () => {
    const orderDetails = {
      ...userDetails,
      items: cart.map((item) => `${item.product.name} x ${item.quantity}`),
      totalAmount: total,
      deliveryFee: shippingFee,
    };

    try {
      const response = await fetch("https://formspree.io/f/xeqbbejn", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(orderDetails),
      });

      if (response.ok) {
        console.log("Order details sent successfully");
      } else {
        console.error("Error sending order details");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const usdToNgn = (usdAmount) => {
    const exchangeRate = 1500; // 1500 NGN per 1 USD
    return Math.round(usdAmount * exchangeRate);
  };

  const paystackOptions = {
    email: userDetails.email,
    amount: usdToNgn(total) * 100,
    currency: "NGN",
    publicKey: "pk_live_31623679265ad901114d505a0e83109332561007",
    text: "Pay Now via Paystack",
    onSuccess: async (response) => {
      try {
        const [orderResponse, customerEmail, businessEmail] = await Promise.all(
          [sendOrderDetails(), sendCustomerEmail(), sendBusinessEmail()]
        );

        console.log("All operations completed successfully", {
          orderResponse,
          customerEmail,
          businessEmail,
          paystackResponse: response,
        });

        setCart([]);
        setPaymentSuccessful(true);
      } catch (error) {
        console.error("Error in payment success handling:", error);
        alert(
          "Payment successful but there was an error sending confirmation emails."
        );
      }
    },
    onClose: () => {
      console.log("Payment Closed!");
    },
  };

  const handlePaypalSuccess = async (details) => {
    try {
      console.log("PayPal payment successful", details);
      
      // Create order data
      const orderData = {
        orderItems: cart.map(item => ({
          product: item.product._id || item.product.id,
          quantity: item.quantity,
          size: selectedSize[item.product.id] || "N/A"
        })),
        shippingAddress: userDetails.address,
        city: State.getStateByCodeAndCountry(selectedState, selectedCountry)?.name || selectedState,
        zip: postalCode,
        country: Country.getCountryByCode(selectedCountry)?.name || selectedCountry,
        phone: userDetails.phoneNumber,
        user: userDetails.email, // Using email as user identifier
        totalPrice: total,
        paymentMethod: "PayPal",
        paymentResult: {
          id: details.id,
          status: details.status,
          update_time: new Date().toISOString(),
          email_address: details.payer?.email_address || userDetails.email
        },
        shippingCost: shippingFee
      };
      
      // Get the server URL from environment variables
      const serverUrl = process.env.REACT_APP_SERVER_URL || 'http://localhost:3030';
      
      // Send order to backend
      let orderCreated = false;
      try {
        const response = await fetch(`${serverUrl}/api/v1/orders`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(orderData)
        });
        
        if (!response.ok) {
          const errorText = await response.text();
          console.error("Order creation failed:", response.status, errorText);
          throw new Error(`Failed to create order: ${response.status} ${errorText}`);
        }
        
        const data = await response.json();
        console.log("Order details sent successfully", data);
        orderCreated = true;
      } catch (error) {
        console.error("Error sending order details:", error);
        alert("Your payment was successful, but we couldn't create your order in our system. Please contact customer support with your PayPal transaction ID: " + details.id);
        // Don't proceed to success state if order creation failed
        return;
      }
      
      // Email services commented out as requested
      /*
      try {
        await sendBusinessEmail();
      } catch (error) {
        console.error("Failed to send business email:", error);
        // Continue even if email fails
      }
      */
      
      // Only clear cart and show success if order was created
      if (orderCreated) {
        setCart([]);
        setPaymentSuccessful(true);
      }
      
    } catch (error) {
      console.error("Error in PayPal payment success handling:", error);
      alert("Your payment was successful, but there was an issue processing your order. Please contact customer support with your PayPal transaction ID: " + details.id);
    }
  };

  const toggleSummary = () => {
    setShowSummary((prevShowSummary) => !prevShowSummary);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Form validation
    const requiredFields = [
      "firstName",
      "lastName",
      "email",
      "phoneNumber",
      "address",
    ];
    const missingFields = requiredFields.filter((field) => !userDetails[field]);

    if (missingFields.length > 0) {
      alert("Please fill in all required fields");
      return;
    }

    if (!selectedCountry || !selectedState) {
      alert("Please select your country and state");
      return;
    }

    if (selectedPaymentOption === "bankTransfer") {
      try {
        const [orderResponse, customerEmail, businessEmail] = await Promise.all(
          [sendOrderDetails(), sendCustomerEmail(), sendBusinessEmail()]
        );

        console.log("All operations completed successfully", {
          orderResponse,
          customerEmail,
          businessEmail,
        });

        setShowConfirmPaymentModal(true);
      } catch (error) {
        console.error("Error in bank transfer handling:", error);
        alert("There was an error processing your order. Please try again.");
      }
    }
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setUserDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const getCountryFlag = (countryCode) => {
    return countryCode
      ? `https://flagcdn.com/16x12/${countryCode.toLowerCase()}.png`
      : "";
  };

  useEffect(() => {
    emailjs.init(EMAILJS_PUBLIC_KEY);
  }, []);

  const sendBusinessEmail = async () => {
    try {
      const fullName = `${userDetails.firstName} ${userDetails.lastName}`;
      const orderNumber = `ORD-${Date.now()}`;

      const templateParams = {
        order_number: orderNumber,
        customer_name: fullName,
        customer_email: userDetails.email,
        customer_phone: userDetails.phoneNumber,
        order_items: cart
          .map(
            (item) =>
              `${item.product.name} (Size: ${
                selectedSize[item.product.id] || "N/A"
              }) x ${item.quantity}`
          )
          .join("\n"),
        subtotal: `$${new Intl.NumberFormat("en-NG").format(subtotal)}`,
        shipping: `$${new Intl.NumberFormat("en-NG").format(shippingFee)}`,
        total: `$${new Intl.NumberFormat("en-NG").format(total)}`,
        delivery_address: userDetails.address,
        postal_code: postalCode,
        country:
          Country.getCountryByCode(selectedCountry)?.name || selectedCountry,
        state:
          State.getStateByCodeAndCountry(selectedState, selectedCountry)
            ?.name || selectedState,
        payment_method:
          selectedPaymentOption === "paystack" ? "Paystack" : "Bank Transfer",
        payment_status:
          selectedPaymentOption === "paystack"
            ? "Paid"
            : "Bank Transfer Pending",
        to_email: "support@dontbrag.com", // Add business email here
      };

      const response = await emailjs.send(
        EMAILJS_SERVICE_ID,
        TEMPLATE_ID_BUSINESS,
        templateParams
      );

      console.log("Business email sent successfully:", response);
      return response;
    } catch (error) {
      console.error("Failed to send business email:", error);
      throw error;
    }
  };

  const sendCustomerEmail = async () => {
    try {
      const fullName = `${userDetails.firstName} ${userDetails.lastName}`;
      const orderNumber = `ORD-${Date.now()}`;

      const templateParams = {
        to_name: fullName,
        to_email: userDetails.email, // This is crucial for the customer email
        order_number: orderNumber,
        order_items: cart
          .map(
            (item) =>
              `${item.product.name} (Size: ${
                selectedSize[item.product.id] || "N/A"
              }) x ${item.quantity}`
          )
          .join("\n"),
        subtotal: `$${new Intl.NumberFormat("en-NG").format(subtotal)}`,
        shipping: `$${new Intl.NumberFormat("en-NG").format(shippingFee)}`,
        total: `$${new Intl.NumberFormat("en-NG").format(total)}`,
        delivery_address: `${userDetails.address}, ${
          State.getStateByCodeAndCountry(selectedState, selectedCountry)
            ?.name || selectedState
        }, ${
          Country.getCountryByCode(selectedCountry)?.name || selectedCountry
        }`,
        email: userDetails.email, // Add this for template routing
      };

      const response = await emailjs.send(
        EMAILJS_SERVICE_ID,
        TEMPLATE_ID_CUSTOMER,
        templateParams
      );

      console.log("Customer email sent successfully:", response);
      return response;
    } catch (error) {
      console.error("Failed to send customer email:", error);
      throw error;
    }
  };

  // Add useEffect to handle PayPal return
  useEffect(() => {
    // Check if the URL contains PayPal success parameters
    const urlParams = new URLSearchParams(window.location.search);
    const success = urlParams.get('success');
    const canceled = urlParams.get('canceled');
    const paymentId = urlParams.get('tx') || urlParams.get('txn_id');
    
    // Log all URL parameters for debugging
    console.log('URL Parameters:', Object.fromEntries([...urlParams]));
    
    // Handle PayPal cancellation
    if (canceled === 'true') {
      console.log('PayPal payment was canceled by the user');
      alert('PayPal payment was canceled. Please try again or choose another payment method.');
      // Clean up the URL
      window.history.replaceState({}, document.title, window.location.pathname);
      return;
    }
    
    // Handle PayPal success
    if (success === 'true') {
      console.log('PayPal payment success detected in URL');
      // Show loading indicator
      setIsLoading(true);
      
      // Log transaction details
      console.log('Transaction ID:', paymentId || 'Not provided in return URL');
      console.log('User Details:', userDetails);
      
      // Create a payment details object with the transaction ID
      const paymentDetails = {
        id: paymentId || 'PAYPAL-' + Date.now(),
        status: 'COMPLETED',
        payer: {
          email_address: userDetails.email,
          name: {
            given_name: userDetails.firstName,
            surname: userDetails.lastName
          }
        },
        purchase_units: [{
          amount: {
            value: total.toFixed(2)
          }
        }]
      };
      
      console.log('Payment details being sent to handlePaypalSuccess:', paymentDetails);
      
      // Process the successful payment
      handlePaypalSuccess(paymentDetails);
      
      // Clean up the URL
      window.history.replaceState({}, document.title, window.location.pathname);
      setIsLoading(false);
      
      // Close the PayPal modal if it's open
      setShowPaypalModal(false);
      
      // Show success message to the user
      alert('Payment successful! Your order has been placed.');
    }
  }, [userDetails.email, userDetails.firstName, userDetails.lastName, total]);  // Dependencies for the useEffect

  return (
    <div className="checkout-container">
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
          <p>Processing your PayPal payment...</p>
        </div>
      )}
      
      {paymentSuccessful && cart.length === 0 ? (
        <div className="success-message">
          <iframe src="https://lottie.host/embed/4056d5d7-2cc3-4b68-9ea7-9d1969139431/CjMowrygcC.json"></iframe>
          <button className="continue-shopping-button">Shop Again</button>
        </div>
      ) : (
        <>
          <div className="checkout-summary">
            <div className="summary-toggle" onClick={toggleSummary}>
              <p>
                {showSummary ? "Hide" : "Show"} order summary{" "}
                <span>
                  <MdOutlineArrowDropDown />
                </span>
              </p>
              <p>${new Intl.NumberFormat("en-NG").format(total)}</p>
            </div>
            {showSummary && (
              <div className="summary-content">
                {cart.map((item, index) => (
                  <div key={index} className="sumcon">
                    <div className="sumcon-image">
                      <img
                        src={item.product.image}
                        alt="images"
                        className="checkoutImage"
                      />
                      <p className="checkoutimageNumber">{item.quantity}</p>
                    </div>
                    <div className="summaryOerder">
                      <p className="summaryOerderF summaryOerderName">
                        {item.product.name} <br />
                        <span style={{ fontSize: 13, margin: 0 }}>
                          size: {selectedSize[item.product.id] || "N/A"}
                        </span>
                      </p>

                      <p className="summaryOerderF">
                        $
                        {new Intl.NumberFormat("en-NG").format(
                          item.product.price * item.quantity
                        )}
                      </p>
                    </div>
                  </div>
                ))}
                <div className="buttomCartCheckout">
                  <span className="cartTotal">
                    <p className="subtotal">Subtotal:</p>
                    <p className="total">
                      ${new Intl.NumberFormat("en-NG").format(subtotal)}
                    </p>
                  </span>
                  <span className="shipping">
                    <p className="shiptext">Shipping:</p>
                    <p className="total">
                      ${new Intl.NumberFormat("en-NG").format(shippingFee)}
                    </p>
                  </span>
                  <span className="shipping">
                    <p className="shiptext">Total:</p>
                    <p className="total">
                      ${new Intl.NumberFormat("en-NG").format(total)}
                    </p>
                  </span>
                </div>
              </div>
            )}

            <div className="paymentDelivery">
              <h3>Delivery</h3>
              <form onSubmit={handleSubmit} className="formWrapper">
                <div className="form-group">
                  <select
                    className="form-control"
                    value={selectedCountry}
                    onChange={(e) => {
                      setSelectedCountry(e.target.value);
                      setSelectedState("");
                      setSelectedCity("");
                    }}
                    required
                  >
                    <option value="">Country/Region</option>
                    {Country.getAllCountries().map((country) => (
                      <option key={country.isoCode} value={country.isoCode}>
                        {country.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="form-group">
                  <select
                    className="form-control"
                    value={selectedState}
                    onChange={(e) => {
                      setSelectedState(e.target.value);
                      setSelectedCity("");
                    }}
                    disabled={!selectedCountry}
                    required
                  >
                    <option value="">State</option>
                    {State.getStatesOfCountry(selectedCountry).map((state) => (
                      <option key={state.isoCode} value={state.isoCode}>
                        {state.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Postal Code"
                    value={postalCode}
                    onChange={(e) => setPostalCode(e.target.value)}
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    name="address"
                    className="form-control"
                    placeholder="Address"
                    value={userDetails.address}
                    onChange={handleFormChange}
                    required
                  />
                </div>

                <div className="form-group">
                  <input
                    type="text"
                    name="lastName"
                    className="form-control"
                    placeholder="Last Name"
                    value={userDetails.lastName}
                    onChange={handleFormChange}
                    required
                  />
                </div>

                <div className="form-group">
                  <input
                    type="text"
                    name="firstName"
                    className="form-control"
                    placeholder="First Name"
                    value={userDetails.firstName}
                    onChange={handleFormChange}
                    required
                  />
                </div>

                <div className="form-group">
                  <input
                    type="email"
                    name="email"
                    className="form-control"
                    placeholder="Email"
                    value={userDetails.email}
                    onChange={handleFormChange}
                    required
                  />
                </div>

                <div className="form-group">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <img
                        src={getCountryFlag(selectedCountry)}
                        alt={selectedCountry}
                        className="country-flag"
                      />
                    </div>
                    <input
                      type="tel"
                      name="phoneNumber"
                      className="form-control"
                      placeholder="Phone"
                      value={userDetails.phoneNumber}
                      onChange={handleFormChange}
                      required
                    />
                  </div>
                </div>

                <h3>Payment</h3>
                {selectedCountry && (
                  <p className="within">
                    {selectedCountry === "NG"
                      ? "Shipping within Nigeria: $10"
                      : "International shipping: $80"}
                  </p>
                )}
                <p className="tran">
                  All transactions are secure and encrypted
                </p>
                <div className="payment-options">
                  <div className="payment-option">
                    <input
                      type="radio"
                      id="paystack"
                      name="payment-method"
                      checked={selectedPaymentOption === "paystack"}
                      onChange={handleShowModal}
                    />
                    <label htmlFor="paystack">Paystack</label>
                    <div className="payment-logos">
                      <img src={PaymentImg} alt="Payment Options" />
                    </div>
                  </div>
                  
                  <div className="payment-option">
                    <input
                      type="radio"
                      id="paypal"
                      name="payment-method"
                      checked={selectedPaymentOption === "paypal"}
                      onChange={handlePaypalModal}
                    />
                    <label htmlFor="paypal">PayPal / Credit Card (Sandbox Mode)</label>
                    <div className="payment-logos">
                      <img 
                        src="https://www.paypalobjects.com/webstatic/mktg/logo/pp_cc_mark_37x23.jpg" 
                        alt="PayPal" 
                        style={{ height: '23px' }}
                      />
                    </div>
                  </div>
                  
                  <div className="payment-option">
                    <input
                      type="radio"
                      id="stripe"
                      name="payment-method"
                      checked={selectedPaymentOption === "stripe"}
                      onChange={handleStripeModal}
                    />
                    <label htmlFor="stripe">Credit Card (Stripe)</label>
                    <div className="payment-logos">
                      <img 
                        src="https://js.stripe.com/v3/fingerprinted/img/visa-729c05c240c4bdb47b03ac81d9945bfe.svg" 
                        alt="Visa" 
                        style={{ height: '20px', marginRight: '5px' }}
                      />
                      <img 
                        src="https://js.stripe.com/v3/fingerprinted/img/mastercard-4d8844094130711885b5e41b28c9848f.svg" 
                        alt="Mastercard" 
                        style={{ height: '20px' }}
                      />
                    </div>
                  </div>
                  
                  <div className="payment-option">
                    <input
                      type="radio"
                      id="bank-transfer"
                      name="payment-method"
                      checked={selectedPaymentOption === "bankTransfer"}
                      onChange={handleBankModal}
                    />
                    <label htmlFor="bank-transfer">Bank Transfer</label>
                  </div>
                </div>
                {showPaymentModal && (
                  <div className="PayStackMo">
                    <img src={PaymentImg} alt="opayemntImg" />
                    <p>
                      After clicking "Pay now", you will be redirected to
                      Paystack to complete your purchase securely
                    </p>
                  </div>
                )}

                <div className="summary-content">
                  {cart.map((item, index) => (
                    <div key={index} className="sumcon">
                      <div className="sumcon-image">
                        <img
                          src={item.product.image}
                          alt="images"
                          className="checkoutImage"
                        />
                        <p className="checkoutimageNumber">{item.quantity}</p>
                      </div>
                      <div className="summaryOerder">
                        <p className="summaryOerderF summaryOerderName">
                          {item.product.name} <br />
                          <span style={{ fontSize: 13, margin: 0 }}>
                            size: {selectedSize[item.product.id] || "N/A"}
                          </span>
                        </p>
                        <p className="summaryOerderF">
                          $
                          {new Intl.NumberFormat("en-NG").format(
                            item.product.price * item.quantity
                          )}
                        </p>
                      </div>
                    </div>
                  ))}
                  <div className="buttomCartCheckout">
                    <span className="cartTotal">
                      <p className="subtotal">Subtotal:</p>
                      <p className="total">
                        ${new Intl.NumberFormat("en-NG").format(subtotal)}
                      </p>
                    </span>
                    <span className="shipping">
                      <p className="shiptext">Shipping:</p>
                      <p className="total">
                        ${new Intl.NumberFormat("en-NG").format(shippingFee)}
                      </p>
                    </span>
                    <span className="shipping">
                      <p className="shiptext">Total:</p>
                      <p className="total">
                        ${new Intl.NumberFormat("en-NG").format(total)}
                      </p>
                    </span>
                  </div>
                </div>
                <div className="bottomButton">
                  <div className="form-group">
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="saveInfo"
                      />
                      <label className="form-check-label" htmlFor="saveInfo">
                        Save this information for next time
                      </label>
                    </div>
                  </div>
                  <div className="form-group">
                    {selectedPaymentOption === "paystack" ? (
                      <PaystackButton
                        {...paystackOptions}
                        className="btnPaynows"
                      />
                    ) : selectedPaymentOption === "paypal" ? (
                      <div className="paypal-button-container">
                        <PayPalButtonWrapper
                          total={total}
                          userDetails={userDetails}
                          onApprove={(details) => {
                            handlePaypalSuccess(details);
                          }}
                          onError={(err) => {
                            console.error("PayPal Error in checkout:", err);
                            alert("There was an error processing your payment. Please try again or choose another payment method.");
                          }}
                        />
                      </div>
                    ) : selectedPaymentOption === "stripe" ? (
                      <div className="stripe-button-container">
                        <StripeButtonWrapper
                          total={total}
                          userDetails={userDetails}
                          onApprove={(details) => {
                            handlePaypalSuccess(details);
                          }}
                          onError={(err) => {
                            console.error("Stripe Error in checkout:", err);
                            alert("There was an error processing your payment. Please try again or choose another payment method.");
                          }}
                        />
                      </div>
                    ) : (
                      <button
                        type="button"
                        className="btnPaynows"
                        onClick={() => setShowConfirmPaymentModal(true)}
                      >
                        Pay Now
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
          {showConfirmPaymentModal && (
            <ConfirmPaymentModal
              onClose={() => setShowConfirmPaymentModal(false)}
              onPaymentMade={() => {
                setShowConfirmPaymentModal(false);
                // const whatsappNumber = "+2347072855007";
                const message = encodeURIComponent(
                  "Hello, I've made a payment for my order. Here's my receipt: "
                );
                const whatsappUrl = `https://wa.me/${+2348137816749}?text=${message}`;
                window.open(whatsappUrl, "_blank");
              }}
            />
          )}
          {showPaypalModal && (
            <div className="payment-modal">
              <div className="modal-content">
                <h3>Pay with PayPal or Card</h3>
                <p className="modal-subtitle">Total: ${total.toFixed(2)}</p>
                
                <div className="payment-method-info">
                  <div className="payment-method-header">
                    <h4>Payment Options</h4>
                    <p>Choose your preferred payment method:</p>
                  </div>
                  
                  <div className="payment-method-options">
                    <div className="payment-method-option">
                      <img src="https://www.paypalobjects.com/webstatic/mktg/logo/pp_cc_mark_37x23.jpg" alt="PayPal" />
                      <div className="option-details">
                        <h5>PayPal Account</h5>
                        <p>Pay securely using your PayPal account</p>
                      </div>
                    </div>
                    
                    <div className="payment-method-option">
                      <img src="https://www.paypalobjects.com/webstatic/en_US/i/buttons/cc-badges-ppmcvdam.png" alt="Credit Cards" />
                      <div className="option-details">
                        <h5>Credit or Debit Card</h5>
                        <p>Pay with your card (no PayPal account required)</p>
                      </div>
                    </div>
                    
                    <div className="payment-method-option">
                      <img src="https://www.paypalobjects.com/webstatic/en_US/i/buttons/bank-logo.png" alt="Bank" />
                      <div className="option-details">
                        <h5>Bank Account</h5>
                        <p>Pay directly from your bank account</p>
                      </div>
                    </div>
                  </div>
                </div>
                
                <div className="paypal-note">
                  <p><strong>Important:</strong> You will be redirected to PayPal's secure payment page to complete your payment.</p>
                </div>
                
                <div className="paypal-troubleshooting">
                  <details>
                    <summary>Troubleshooting Tips</summary>
                    <ul>
                      <li>Make sure pop-ups are not blocked in your browser</li>
                      <li>If you don't see the card payment option, try using a different browser</li>
                      <li>Clear your browser cache and cookies if you encounter errors</li>
                      <li>If you're using a VPN, try disabling it</li>
                      <li>Some ad blockers may interfere with PayPal - try disabling them</li>
                    </ul>
                  </details>
                </div>
                
                <div className="paypal-button-container">
                  <PayPalButtonWrapper
                    total={total}
                    userDetails={userDetails}
                    onApprove={(details) => {
                      handlePaypalSuccess(details);
                      setShowPaypalModal(false);
                    }}
                    onError={(err) => {
                      console.error("PayPal Error in checkout:", err);
                      alert("There was an error processing your payment. Please try again or choose another payment method.");
                      setShowPaypalModal(false);
                    }}
                  />
                </div>
                
                <button 
                  className="close-modal-btn" 
                  onClick={() => setShowPaypalModal(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          )}
          {showBankModal && (
            <div className="payment-modal">
              <div className="modal-content">
                <h3>Bank Transfer Payment</h3>
                <p>Total: ${total.toFixed(2)} (₦{usdToNgn(total).toLocaleString()})</p>
                
                <div className="bank-info">
                  <div className="bank-info-header">
                    <h4>Transfer Details</h4>
                    <p>Please transfer the exact amount in Nigerian Naira (NGN)</p>
                  </div>
                  
                  <div className="bank-details">
                    <div className="bank-detail-item">
                      <span className="detail-label">Account Name:</span>
                      <span className="detail-value">DON'T BRAG LUXURY</span>
                    </div>
                    <div className="bank-detail-item">
                      <span className="detail-label">Account Number:</span>
                      <span className="detail-value">8137816749</span>
                    </div>
                    <div className="bank-detail-item">
                      <span className="detail-label">Bank Name:</span>
                      <span className="detail-value">MONIEPOINT MFB</span>
                    </div>
                    <div className="bank-detail-item">
                      <span className="detail-label">Amount:</span>
                      <span className="detail-value">₦{usdToNgn(total).toLocaleString()}</span>
                    </div>
                    <div className="bank-detail-item">
                      <span className="detail-label">Reference:</span>
                      <span className="detail-value">{userDetails.firstName} {userDetails.lastName}</span>
                    </div>
                  </div>
                  
                  <div className="bank-note">
                    <p><strong>Important:</strong> After making the transfer, please contact us with your proof of payment.</p>
                    <p>Contact: <a href="tel:+2348137816749">+234 813 781 6749</a></p>
                  </div>
                  
                  <div className="exchange-rate-info">
                    <p>Exchange Rate: $1 = ₦1,500</p>
                    <p>All payments are confirmed before processing orders.</p>
                  </div>
                </div>
                
                <div className="bank-actions">
                  <button 
                    className="confirm-transfer-btn" 
                    onClick={() => {
                      setShowBankModal(false);
                      setShowConfirmPaymentModal(true);
                    }}
                  >
                    I've Made the Transfer
                  </button>
                  <button 
                    className="close-modal-btn" 
                    onClick={() => setShowBankModal(false)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          )}
          {showStripeModal && (
            <div className="payment-modal">
              <div className="modal-content">
                <h3>Pay with Credit Card</h3>
                <p>Total: ${total.toFixed(2)}</p>
                <p className="stripe-info">
                  Enter your card details securely to complete your purchase.
                </p>
                <div className="stripe-button-container">
                  <StripeButtonWrapper
                    total={total}
                    userDetails={userDetails}
                    onApprove={(details) => {
                      handlePaypalSuccess(details);
                      setShowStripeModal(false);
                    }}
                    onError={(err) => {
                      console.error("Stripe Error in checkout:", err);
                      alert("There was an error processing your payment. Please try again or choose another payment method.");
                      setShowStripeModal(false);
                    }}
                  />
                </div>
                <button 
                  className="close-modal-btn" 
                  onClick={() => setShowStripeModal(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          )}
          {isOpen && <PaymentApproved setIsOpen={setIsOpen} />}
        </>
      )}
    </div>
  );
};

export default Checkout;
