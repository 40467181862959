import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectRegion, setManualCurrency } from '../../redux/slice/regionSlice';
import styles from './RegionSelector.module.css';

const currencies = [
  { code: 'USD', name: 'US Dollar ($)' },
  { code: 'GBP', name: 'British Pound (£)' },
  { code: 'EUR', name: 'Euro (€)' },
];

/**
 * Component for selecting currency
 */
const RegionSelector = () => {
  const dispatch = useDispatch();
  const { currency } = useSelector(selectRegion);
  const [isOpen, setIsOpen] = useState(false);
  
  const handleCurrencyChange = (e) => {
    const newCurrencyCode = e.target.value;
    dispatch(setManualCurrency(newCurrencyCode));
  };
  
  return (
    <div className={styles.regionSelector}>
      <button 
        className={styles.selectorButton} 
        onClick={() => setIsOpen(!isOpen)}
        aria-expanded={isOpen}
      >
        {currency.code} ({currency.symbol})
      </button>
      
      {isOpen && (
        <div className={styles.dropdown}>
          <div className={styles.section}>
            <h3 className={styles.sectionTitle}>Select Your Currency</h3>
            <select 
              value={currency.code} 
              onChange={handleCurrencyChange}
              className={styles.select}
            >
              {currencies.map(curr => (
                <option key={curr.code} value={curr.code}>
                  {curr.name}
                </option>
              ))}
            </select>
          </div>
          
          <button 
            className={styles.closeButton}
            onClick={() => setIsOpen(false)}
          >
            Close
          </button>
        </div>
      )}
    </div>
  );
};

/**
 * Minimal currency selector for footer or header
 */
export const MiniRegionSelector = () => {
  const dispatch = useDispatch();
  const { currency } = useSelector(selectRegion);
  const [isOpen, setIsOpen] = useState(false);
  
  const handleCurrencyChange = (e) => {
    const newCurrencyCode = e.target.value;
    dispatch(setManualCurrency(newCurrencyCode));
  };
  
  return (
    <div className={styles.miniSelector}>
      <button 
        className={styles.miniButton} 
        onClick={() => setIsOpen(!isOpen)}
        aria-expanded={isOpen}
      >
        {currency.symbol} {currency.code}
      </button>
      
      {isOpen && (
        <div className={styles.miniDropdown}>
          <select 
            value={currency.code} 
            onChange={handleCurrencyChange}
            className={styles.miniSelect}
          >
            {currencies.map(curr => (
              <option key={curr.code} value={curr.code}>
                {curr.code}
              </option>
            ))}
          </select>
          
          <button 
            className={styles.miniCloseButton}
            onClick={() => setIsOpen(false)}
          >
            ✕
          </button>
        </div>
      )}
    </div>
  );
};

export default RegionSelector; 