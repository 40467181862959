import React, { useEffect } from "react";
import "../styles/about.css";
import { FaQuoteLeft, FaQuoteRight, FaLeaf, FaHandshake, FaHeart, FaRegLightbulb, FaChevronDown } from "react-icons/fa";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import Footer from "../components/Footer";

// Import high-quality fashion images
const fashionImage1 = "https://images.unsplash.com/photo-1558769132-cb1aea458c5e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1074&q=80";
const fashionImage2 = "https://images.unsplash.com/photo-1445205170230-053b83016050?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1171&q=80";
const fashionImage3 = "https://images.unsplash.com/photo-1490481651871-ab68de25d43d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80";

const About = () => {
  // Animation variants
  const fadeIn = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: { duration: 0.6 }
    }
  };
  
  const staggerContainer = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.3
      }
    }
  };
  
  const scaleIn = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: { 
      opacity: 1, 
      scale: 1,
      transition: { duration: 0.6 }
    }
  };
  
  // Scroll animation for parallax effect
  const [heroRef, heroInView] = useInView({
    triggerOnce: true,
    threshold: 0.1
  });
  
  const heroControls = useAnimation();
  
  useEffect(() => {
    if (heroInView) {
      heroControls.start("visible");
    }
  }, [heroControls, heroInView]);

  return (
    <div className="about-page">
      {/* Hero Section with Image */}
      <section className="about-hero" ref={heroRef}>
        <div className="hero-image" style={{ backgroundImage: `url(${fashionImage3})` }}></div>
        <div className="hero-overlay"></div>
        <div className="about-container">
          <motion.div
            className="hero-content"
            variants={{
              hidden: { opacity: 0 },
              visible: { opacity: 1 }
            }}
            initial="hidden"
            animate={heroControls}
          >
            <motion.div 
              className="hero-text-container"
              variants={{
                hidden: { opacity: 0, y: 30 },
                visible: { 
                  opacity: 1, 
                  y: 0,
                  transition: { duration: 0.7, ease: "easeOut" }
                }
              }}
            >
              <h1>DON'T BRAG</h1>
              <p className="hero-tagline">
                Fashion that speaks for itself
              </p>
              <div className="hero-description">
                Elevating style through understated elegance and authentic expression
              </div>
            </motion.div>
            
            <motion.div 
              className="scroll-indicator"
              variants={{
                hidden: { opacity: 0 },
                visible: { 
                  opacity: 1,
                  transition: { 
                    delay: 1.2,
                    repeat: Infinity,
                    repeatType: "reverse",
                    duration: 1.5
                  }
                }
              }}
            >
              <FaChevronDown />
            </motion.div>
          </motion.div>
        </div>
      </section>

      {/* Our Story Section with Image */}
      <section className="about-section">
        <div className="about-container">
          <motion.div 
            className="about-grid"
            variants={staggerContainer}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.3 }}
          >
            <motion.div className="about-content" variants={fadeIn}>
              <div className="section-badge">Our Journey</div>
              <h2>Our Story</h2>
              <p>
                At DON'T BRAG, we believe that true style speaks for itself. Our
                name isn't just a label; it's a philosophy. We embrace the beauty of
                understatement and the power of authenticity. Instead of boasting,
                we let our designs do the talking. DON'T BRAG is a celebration of
                humility, elegance, and genuine self-expression. Join us on a
                journey where style is a statement, not a shout.
              </p>
              <div className="content-accent">
                <span>Est. 2023</span>
              </div>
            </motion.div>
            <motion.div 
              className="about-image-container" 
              variants={scaleIn}
            >
              <div className="about-image limited-edition">
                <img src={fashionImage1} alt="Fashion collection from Don't Brag" />
                <div className="image-overlay">
                  <span className="limited-badge">Limited Edition</span>
                </div>
              </div>
            </motion.div>
          </motion.div>
        </div>
      </section>

      {/* Quote Section with Gradient Background */}
      <section className="quote-section">
        <div className="quote-pattern"></div>
        <div className="about-container">
          <motion.div 
            className="quote-content"
            initial={{ opacity: 0, scale: 0.95 }}
            whileInView={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: true }}
          >
            <div className="section-badge">Our Ethos</div>
            <h2>Our Philosophy</h2>
            <div className="quote-text">
              <FaQuoteLeft className="quote-icon left" />
              <p>
                At DON'T BRAG, we are more than just a fashion brand;
                we are a movement. Our name, DON'T BRAG, encapsulates our ethos of
                humility and authenticity. We believe that true style doesn't need to
                shout; it speaks volumes in its subtlety. By embracing understated
                elegance and genuine self-expression, we redefine the fashion
                narrative. DON'T BRAG is a testament to the power of quiet confidence
                and the beauty of letting our designs shine without the need for
                excessive self-promotion.
              </p>
              <FaQuoteRight className="quote-icon right" />
            </div>
          </motion.div>
        </div>
      </section>

      {/* Values Section with Hover Effects */}
      <section className="values-section">
        <div className="about-container">
          <motion.div
            className="values-header"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            viewport={{ once: true }}
          >
            <div className="section-badge">What We Stand For</div>
            <h2 className="section-title">Our Values</h2>
            <p className="values-intro">
              These core principles guide everything we do, from design to customer experience.
            </p>
          </motion.div>
          
          <motion.div 
            className="values-grid"
            variants={staggerContainer}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.1 }}
          >
            <motion.div className="value-card" variants={fadeIn}>
              <div className="value-icon">
                <FaLeaf />
              </div>
              <h3>Sustainability</h3>
              <p>We're committed to ethical practices and sustainable fashion that respects our planet.</p>
            </motion.div>
            
            <motion.div className="value-card" variants={fadeIn}>
              <div className="value-icon">
                <FaHandshake />
              </div>
              <h3>Authenticity</h3>
              <p>We believe in being true to ourselves and creating genuine connections with our customers.</p>
            </motion.div>
            
            <motion.div className="value-card" variants={fadeIn}>
              <div className="value-icon">
                <FaHeart />
              </div>
              <h3>Inclusivity</h3>
              <p>We celebrate diversity and create fashion that empowers everyone to express themselves.</p>
            </motion.div>
            
            <motion.div className="value-card" variants={fadeIn}>
              <div className="value-icon">
                <FaRegLightbulb />
              </div>
              <h3>Innovation</h3>
              <p>We constantly push boundaries to bring fresh, creative designs to our community.</p>
            </motion.div>
          </motion.div>
        </div>
      </section>

      {/* Mission Section with Image */}
      <section className="mission-section">
        <div className="about-container">
          <motion.div 
            className="mission-grid"
            variants={staggerContainer}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.3 }}
          >
            <motion.div 
              className="mission-image" 
              variants={scaleIn}
            >
              <img src={fashionImage2} alt="Fashion design process at Don't Brag" />
            </motion.div>
            
            <motion.div className="mission-content" variants={fadeIn}>
              <div className="section-badge">Looking Forward</div>
              <h2>Our Mission</h2>
              <p className="gradient-text">
                To create fashion that empowers individuals to express their authentic selves without the need for validation. 
                We strive to build a community where style is appreciated for its substance rather than its showiness, 
                and where every piece tells a story of quality, craftsmanship, and conscious creation.
              </p>
            </motion.div>
          </motion.div>
        </div>
      </section>

      {/* Call to Action Section */}
      <section className="cta-section">
        <div className="about-container">
          <motion.div 
            className="cta-content"
            initial={{ opacity: 0, scale: 0.9 }}
            whileInView={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.6 }}
            viewport={{ once: true }}
          >
            <h2>Join Our Journey</h2>
            <p>Experience fashion that speaks volumes without saying a word.</p>
            <motion.a 
              href="/shop" 
              className="cta-button"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              Shop Collection
            </motion.a>
          </motion.div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default About;
