// ProductItem.js
import React from "react";
import { ProductImage, ProductImageWrapper, ProductItemBottom } from "./styles";
import ContentLoader from "react-content-loader";
import styled from "styled-components";

const ProductCard = styled.div`
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  }
`;

const ProductName = styled.p`
  font-weight: 500;
  margin-bottom: 5px;
  color: #333;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ProductPrice = styled.p`
  font-weight: 700;
  color: #500157;
  font-size: 16px;
  margin: 0;
`;

const ProductItem = ({ product, onClick }) => {
  return (
    <ProductCard onClick={onClick}>
      <ProductImageWrapper>
        {product.image ? (
          <ProductImage
            src={product.image}
            alt={product.name}
            style={{ width: "100%", height: "200px", objectFit: "cover" }}
          />
        ) : (
          <ContentLoader
            speed={2}
            width="100%"
            height={150}
            viewBox="0 0 100% 150"
            backgroundColor="#F2F2F2"
            foregroundColor="#F2F2F2"
          >
            <rect x="0" y="0" rx="10" ry="10" width="100%" height="150" />
          </ContentLoader>
        )}
      </ProductImageWrapper>

      <ProductItemBottom>
        <ProductName>{product.name}</ProductName>
        <ProductPrice>${new Intl.NumberFormat("en-NG").format(product.price)}</ProductPrice>
      </ProductItemBottom>
    </ProductCard>
  );
};

export default ProductItem;
