import { createSlice } from '@reduxjs/toolkit';

// Define currency symbols and codes
const currencies = {
    USD: { symbol: '$', code: 'USD', name: 'US Dollar' },
    CAD: { symbol: 'C$', code: 'CAD', name: 'Canadian Dollar' },
    GBP: { symbol: '£', code: 'GBP', name: 'British Pound' },
    EUR: { symbol: '€', code: 'EUR', name: 'Euro' },
    NGN: { symbol: '₦', code: 'NGN', name: 'Nigerian Naira' },
    ZAR: { symbol: 'R', code: 'ZAR', name: 'South African Rand' },
};

// Define shipping rates by region
const shippingRates = {
    africa: {
        NG: 5.00,
        default: 10.00,
    },
    default: 10.00,
};

// Define exchange rates (relative to USD)
const exchangeRates = {
    USD: 1,
    CAD: 1.35,
    GBP: 0.78,
    EUR: 0.92,
    NGN: 1550,
    ZAR: 18.5,
};

// Helper function to determine region from country code
const getRegionFromCountry = (countryCode) => {
    const africa = ['NG', 'ZA', 'GH', 'KE', 'EG', 'MA', 'TN', 'DZ', 'ET', 'UG', 'TZ'];

    if (africa.includes(countryCode)) return 'africa';
    return 'default';
};

// Helper function to determine currency from country code
const getCurrencyFromCountry = (countryCode) => {
    // North America
    if (countryCode === 'US') return currencies.USD;
    if (countryCode === 'CA') return currencies.CAD;

    // Europe
    if (countryCode === 'GB') return currencies.GBP;
    if (['DE', 'FR', 'IT', 'ES', 'NL', 'BE', 'PT', 'AT', 'IE', 'FI'].includes(countryCode)) return currencies.EUR;

    // Africa
    if (countryCode === 'NG') return currencies.NGN;
    if (countryCode === 'ZA') return currencies.ZAR;

    // Default to USD for other regions
    return currencies.USD;
};

// Helper function to get shipping cost
const getShippingCost = (countryCode, region) => {
    if (!region) region = getRegionFromCountry(countryCode);

    const regionRates = shippingRates[region] || shippingRates.default;
    return regionRates[countryCode] || regionRates.default;
};

const initialState = {
    countryCode: 'US',
    region: 'default',
    currency: currencies.USD,
    shippingCost: 10.00,
    exchangeRate: 1,
    freeShippingThreshold: 0, // No free shipping
    shippingDiscountThreshold: 500, // $500 threshold for shipping discount
    shippingDiscountPercent: 20, // 20% discount on shipping
    isLoading: true,
    error: null,
};

const regionSlice = createSlice({
    name: 'region',
    initialState,
    reducers: {
        setUserRegion: (state, action) => {
            const { countryCode } = action.payload;
            const region = getRegionFromCountry(countryCode);
            const currency = getCurrencyFromCountry(countryCode);
            const shippingCost = getShippingCost(countryCode, region);
            const exchangeRate = exchangeRates[currency.code] || 1;

            state.countryCode = countryCode;
            state.region = region;
            state.currency = currency;
            state.shippingCost = shippingCost;
            state.exchangeRate = exchangeRate;
            state.isLoading = false;
        },
        setManualCurrency: (state, action) => {
            const currencyCode = action.payload;
            if (currencies[currencyCode]) {
                state.currency = currencies[currencyCode];
                state.exchangeRate = exchangeRates[currencyCode] || 1;
            }
        },
        setLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setError: (state, action) => {
            state.error = action.payload;
            state.isLoading = false;
        },
    },
});

export const { setUserRegion, setManualCurrency, setLoading, setError } = regionSlice.actions;

// Selectors
export const selectRegion = (state) => state.region;
export const selectCurrency = (state) => state.region.currency;
export const selectShippingCost = (state) => state.region.shippingCost;
export const selectExchangeRate = (state) => state.region.exchangeRate;

// Thunk to fetch user's location and set region
export const fetchUserRegion = () => async(dispatch) => {
    dispatch(setLoading(true));
    try {
        const response = await fetch('https://ipapi.co/json/');
        if (!response.ok) throw new Error('Failed to fetch location data');

        const data = await response.json();
        const countryCode = data.country_code || 'US';

        dispatch(setUserRegion({ countryCode }));
    } catch (error) {
        console.error('Error fetching user region:', error);
        dispatch(setError(error.message));
        // Fall back to default US
        dispatch(setUserRegion({ countryCode: 'US' }));
    }
};

export default regionSlice.reducer;