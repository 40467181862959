/* eslint-disable jsx-a11y/iframe-has-title */
// Cart.js
import React from "react";
import { useCart } from "../components/Cart/cartContext";
import { Link, useNavigate } from "react-router-dom";
import styles from "../styles/cart.module.css";
import { FaArrowLeft, FaShoppingCart, FaTrashAlt, FaLock, FaShoppingBag } from "react-icons/fa";
import { PriceFormatter, SimplePriceFormatter } from "../components/utils/PriceFormatter";
import { ShippingCost } from "../components/utils/ShippingInfo";
import { MiniRegionSelector } from "../components/utils/RegionSelector";
import { useSelector } from "react-redux";
import { selectRegion } from "../redux/slice/regionSlice";

const Cart = () => {
  const { cart, setCart, selectedSize } = useCart();
  const navigate = useNavigate();
  const { shippingCost, shippingDiscountThreshold, shippingDiscountPercent } = useSelector(selectRegion);
  
  const subtotal = cart.reduce(
    (acc, curr) => acc + curr.product.price * curr.quantity,
    0
  );
  
  // Ensure shipping cost is a valid number
  const baseShippingCost = typeof shippingCost === 'number' && !isNaN(shippingCost) ? shippingCost : 10;
  
  // Check if eligible for shipping discount
  const isDiscounted = subtotal >= shippingDiscountThreshold;
  
  // Calculate the shipping cost with discount if applicable
  const finalShippingCost = isDiscounted 
    ? baseShippingCost * (1 - shippingDiscountPercent / 100) 
    : baseShippingCost;
  
  // Calculate total with shipping
  const total = subtotal + finalShippingCost;

  const incrementQuantity = (productId) => {
    const newCart = cart.map((item) =>
      item.product.id === productId
        ? { ...item, quantity: item.quantity + 1 }
        : item
    );
    setCart(newCart);
  };

  const decrementQuantity = (productId) => {
    const newCart = cart.map((item) =>
      item.product.id === productId && item.quantity > 1
        ? { ...item, quantity: item.quantity - 1 }
        : item
    );
    setCart(newCart);
  };

  const removeFromCart = (productId) => {
    const newCart = cart.filter((item) => item.product.id !== productId);
    setCart(newCart);
  };

  const handleCheckout = () => {
    navigate("/checkout");
  };

  if (cart.length === 0) {
    return (
      <div className={styles.cartPage}>
        <Link to="/shop" className={styles.continueShoppingLink}>
          <FaArrowLeft className={styles.continueShoppingIcon} /> Continue Shopping
        </Link>
        
        <div className={styles.emptyCart}>
          <div className={styles.animationContainer}>
            <iframe 
              src="https://lottie.host/embed/aa738e8a-57bd-4d55-a452-99bc37cdd0e5/rFpQfBxcRM.json"
              style={{ width: '100%', height: '200px' }}
              title="Empty cart animation"
            ></iframe>
          </div>
          <h2 className={styles.emptyCartTitle}>Your Cart is Empty</h2>
          <p className={styles.emptyCartMessage}>
            Looks like you haven't added any items to your cart yet. 
            Browse our collection and find something you'll love!
          </p>
          <Link to="/shop">
            <button className={styles.shopNowButton}>
              <FaShoppingCart /> Shop Now
            </button>
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.cartPage}>
      <div className={styles.cartContent}>
        <div className={styles.cartItems}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0.75rem 1.5rem', borderBottom: '1px solid #f0f0f0' }}>
            <div className={styles.cartTitle}>
              <FaShoppingBag style={{ marginRight: '0.5rem', color: '#500157' }} />
              Your Bag <span className={styles.cartCount}>{cart.length}</span>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
              <MiniRegionSelector />
              <Link to="/shop" className={styles.continueShoppingLink}>
                <FaArrowLeft className={styles.continueShoppingIcon} /> Shop More
              </Link>
            </div>
          </div>
          
          <ul className={styles.cartItemsList}>
            {cart.map((item) => (
              <li key={item.product.id} className={styles.cartItem}>
                <img 
                  src={item.product.image} 
                  alt={item.product.name} 
                  className={styles.productImage} 
                />
                
                <div className={styles.productDetails}>
                  <div>
                    <h3 className={styles.productName}>{item.product.name}</h3>
                    <div className={styles.productPrice}>
                      <SimplePriceFormatter amount={item.product.price} />
                    </div>
                    <div className={styles.productMeta}>
                      {selectedSize[item.product.id] && (
                        <span className={styles.productSize}>
                          Size: {selectedSize[item.product.id]}
                        </span>
                      )}
                      {item.product.color && (
                        <span className={styles.productColor}>
                          Color: {item.product.color}
                        </span>
                      )}
                    </div>
                  </div>
                  
                  <div className={styles.quantityControls}>
                    <button 
                      className={styles.quantityButton}
                      onClick={() => decrementQuantity(item.product.id)}
                      disabled={item.quantity <= 1}
                      aria-label="Decrease quantity"
                    >
                      -
                    </button>
                    <span className={styles.quantityValue}>{item.quantity}</span>
                    <button 
                      className={styles.quantityButton}
                      onClick={() => incrementQuantity(item.product.id)}
                      aria-label="Increase quantity"
                    >
                      +
                    </button>
                  </div>
                </div>
                
                <div className={styles.itemActions}>
                  <button 
                    className={styles.removeButton}
                    onClick={() => removeFromCart(item.product.id)}
                    aria-label="Remove item"
                  >
                    <FaTrashAlt size={14} />
                  </button>
                </div>
                <div className={styles.itemTotal}>
                  <SimplePriceFormatter amount={item.product.price * item.quantity} />
                </div>
              </li>
            ))}
          </ul>
        </div>
        
        <div className={styles.cartSummary}>
          <h2 className={styles.summaryTitle}>Order Summary</h2>
          
          <div className={styles.summaryRow}>
            <span className={styles.summaryLabel}>Subtotal ({cart.length} {cart.length === 1 ? 'item' : 'items'})</span>
            <span className={styles.summaryValue}>
              <SimplePriceFormatter amount={subtotal} />
            </span>
          </div>
          
          <div className={styles.summaryRow}>
            <span className={styles.summaryLabel}>Shipping</span>
            <span className={styles.summaryValue}>
              <ShippingCost subtotal={subtotal} />
            </span>
          </div>
          
          <div className={styles.summaryRow}>
            <span className={styles.summaryLabel}>Tax</span>
            <span className={styles.summaryValue}>Calculated at checkout</span>
          </div>
          
          <div className={styles.totalRow}>
            <span className={styles.totalLabel}>Total</span>
            <span className={styles.totalValue}>
              <SimplePriceFormatter amount={total} />
            </span>
          </div>
          
          <button className={styles.checkoutButton} onClick={handleCheckout}>
            <FaLock /> Proceed to Checkout
          </button>
          
          <div style={{ fontSize: '0.75rem', color: '#666', textAlign: 'center', marginTop: '1rem' }}>
            Secure checkout powered by PayPal
          </div>
          
          <div style={{ marginTop: '1.5rem', fontSize: '0.8rem', color: '#666', textAlign: 'center' }}>
            <p style={{ margin: '0.5rem 0' }}>Need help? Contact us</p>
            {subtotal < shippingDiscountThreshold && (
              <p style={{ margin: '0.5rem 0', color: '#500157' }}>
                Add <SimplePriceFormatter amount={shippingDiscountThreshold - subtotal} /> more for {shippingDiscountPercent}% off shipping
              </p>
            )}
            <p style={{ margin: '0.5rem 0' }}>Free returns within 30 days</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cart;
