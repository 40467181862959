// src/redux/store.js
import { configureStore } from "@reduxjs/toolkit";
import featuredProductsReducer from "../slice/featuredProductsSlice";
import regionReducer from "../slice/regionSlice";

export const store = configureStore({
    reducer: {
        featuredProducts: featuredProductsReducer,
        region: regionReducer,
        // ... other reducers
    },
});