// ProductPage.js
import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchFeaturedProducts } from "../../redux/slice/featuredProductsSlice.js"; // Adjust the path as needed
import ProductList from "./ProductList.jsx";
import ProductModal from "./ProductModal.jsx";
import { Container, Row, Col, Form } from "react-bootstrap";
import { FaSort, FaSortAmountDown, FaSortAmountUp, FaFilter, FaTimes, FaChevronDown, FaSearch } from "react-icons/fa";
import styled from "styled-components";

const MobileFilterOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: ${props => props.show ? 'block' : 'none'};
  transition: opacity 0.3s ease;
  opacity: ${props => props.show ? '1' : '0'};
`;

const MobileFilterPanel = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  width: 85%;
  max-width: 350px;
  background-color: white;
  z-index: 1001;
  transform: ${props => props.show ? 'translateX(0)' : 'translateX(100%)'};
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: column;
  box-shadow: -2px 0 10px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
`;

const FilterHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  border-bottom: 1px solid #eee;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 10;
`;

const FilterBody = styled.div`
  padding: 15px 20px;
  flex: 1;
  overflow-y: auto;
`;

const FilterFooter = styled.div`
  padding: 15px 20px;
  border-top: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  position: sticky;
  bottom: 0;
  background-color: white;
  z-index: 10;
`;

const FilterSection = styled.div`
  background-color: #f8f9fa;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);

  @media (max-width: 576px) {
    padding: 15px;
    display: none; /* Hide on mobile, we'll use the slide-in panel instead */
  }
`;

const FilterOption = styled.div`
  margin-bottom: 20px;

  @media (max-width: 576px) {
    margin-bottom: 15px;
  }
`;

const FilterLabel = styled.label`
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
  color: #333;
  font-size: 14px;
`;

const FilterSelect = styled(Form.Control)`
  border-radius: 6px;
  border: 1px solid #ddd;
  padding: 10px;
  font-size: 14px;
  background-color: white;
  
  &:focus {
    border-color: #500157;
    box-shadow: 0 0 0 0.2rem rgba(80, 1, 87, 0.25);
  }
`;

const ShopControls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
  flex-wrap: wrap;
  gap: 15px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
`;

const ControlsGroup = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  flex-wrap: wrap;
  
  @media (max-width: 768px) {
    width: 100%;
    justify-content: space-between;
  }
  
  @media (max-width: 480px) {
    gap: 8px;
  }
`;

const FilterButton = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: #f8f9fa;
  border: 1px solid #e2e2e2;
  color: #333;
  padding: 10px 16px;
  font-size: 14px;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: #e9ecef;
  }

  @media (max-width: 768px) {
    flex: 1;
    justify-content: center;
  }
  
  @media (max-width: 480px) {
    padding: 8px 12px;
    font-size: 13px;
  }
`;

const SortContainer = styled.div`
  position: relative;
  z-index: 100;
  
  @media (max-width: 768px) {
    flex: 1;
  }
`;

const SortButton = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: ${props => props.active ? '#500157' : '#f8f9fa'};
  border: 1px solid #e2e2e2;
  color: ${props => props.active ? 'white' : '#333'};
  padding: 10px 16px;
  font-size: 14px;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 100%;
  justify-content: space-between;

  &:hover {
    background-color: ${props => props.active ? '#3e0143' : '#e9ecef'};
  }
  
  @media (max-width: 480px) {
    padding: 8px 12px;
    font-size: 13px;
  }
`;

const SortDropdown = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  width: 220px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  margin-top: 5px;
  z-index: 1000;
  transition: all 0.3s ease;
  opacity: ${props => props.show ? '1' : '0'};
  visibility: ${props => props.show ? 'visible' : 'hidden'};
  transform: ${props => props.show ? 'translateY(0)' : 'translateY(-10px)'};
  
  @media (max-width: 768px) {
    width: 100%;
    right: 0;
  }
`;

const SortOption = styled.div`
  padding: 12px 16px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  transition: all 0.2s ease;
  color: ${props => props.active ? '#500157' : '#333'};
  background-color: ${props => props.active ? 'rgba(80, 1, 87, 0.05)' : 'transparent'};
  font-weight: ${props => props.active ? '600' : 'normal'};

  &:hover {
    background-color: ${props => props.active ? 'rgba(80, 1, 87, 0.1)' : '#f8f9fa'};
  }
`;

const Button = styled.button`
  background-color: ${props => props.primary ? '#500157' : '#f8f9fa'};
  color: ${props => props.primary ? 'white' : '#333'};
  border: 1px solid ${props => props.primary ? '#500157' : '#e2e2e2'};
  padding: 10px 16px;
  font-size: 14px;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  
  &:hover {
    background-color: ${props => props.primary ? '#3e0143' : '#e9ecef'};
  }
  
  @media (max-width: 480px) {
    padding: 8px 12px;
    font-size: 13px;
  }
`;

const ProductCount = styled.div`
  font-size: 14px;
  color: #666;
  
  @media (max-width: 768px) {
    width: 100%;
    text-align: left;
    margin-top: 5px;
  }
`;

const ActiveFilters = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 15px;
`;

const FilterTag = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  background-color: #f0f0f0;
  border-radius: 20px;
  padding: 4px 12px;
  font-size: 12px;
  color: #333;
  
  button {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: #666;
    
    &:hover {
      color: #500157;
    }
  }
`;

const SearchInput = styled.div`
  position: relative;
  margin-bottom: 20px;
  
  input {
    width: 100%;
    padding: 10px 15px 10px 35px;
    border-radius: 6px;
    border: 1px solid #ddd;
    font-size: 14px;
    
    &:focus {
      outline: none;
      border-color: #500157;
      box-shadow: 0 0 0 0.2rem rgba(80, 1, 87, 0.25);
    }
  }
  
  svg {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: #999;
  }
`;

const ProductPage = () => {
  const dispatch = useDispatch();
  const { products: data, status } = useSelector(
    (state) => state.featuredProducts
  );
  const isLoading = status === "loading";

  const [showModal, setShowModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [filters, setFilters] = useState({
    category: "all",
    priceRange: "all",
    color: "all",
    size: "all",
  });
  const [sortOrder, setSortOrder] = useState("default");
  const [showFilters, setShowFilters] = useState(false);
  const [showSortDropdown, setShowSortDropdown] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  
  const sortRef = useRef(null);
  const filterRef = useRef(null);

  useEffect(() => {
    if (status === "idle") {
      dispatch(fetchFeaturedProducts());
    }
  }, [status, dispatch]);
  
  // Close dropdowns when clicking outside
  useEffect(() => {
    function handleClickOutside(event) {
      if (sortRef.current && !sortRef.current.contains(event.target)) {
        setShowSortDropdown(false);
      }
    }
    
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [sortRef]);

  const handleProductClick = (product) => {
    setSelectedProduct(product);
    setShowModal(true);
  };

  const applyFilters = (products) => {
    if (!products) return [];

    let filteredProducts = [...products];

    // Search filter
    if (searchTerm.trim() !== "") {
      const search = searchTerm.toLowerCase();
      filteredProducts = filteredProducts.filter(
        (product) => 
          product.name.toLowerCase().includes(search) || 
          (product.description && product.description.toLowerCase().includes(search))
      );
    }

    // Filter by category
    if (filters.category !== "all") {
      filteredProducts = filteredProducts.filter(
        (product) =>
          product.category === filters.category ||
          (product.category && product.category.name === filters.category)
      );
    }

    // Filter by price range
    if (filters.priceRange !== "all") {
      const [min, max] = filters.priceRange.split("-").map(Number);
      filteredProducts = filteredProducts.filter(
        (product) => product.price >= min && product.price <= max
      );
    }

    // Filter by color
    if (filters.color !== "all") {
      filteredProducts = filteredProducts.filter(
        (product) =>
          product.colors && product.colors.includes(filters.color)
      );
    }

    // Filter by size
    if (filters.size !== "all") {
      filteredProducts = filteredProducts.filter(
        (product) =>
          product.sizes && product.sizes.includes(filters.size)
      );
    }

    return filteredProducts;
  };

  const applySorting = (products) => {
    let sortedProducts = [...products];

    if (sortOrder === "lowToHigh") {
      sortedProducts.sort((a, b) => a.price - b.price);
    } else if (sortOrder === "highToLow") {
      sortedProducts.sort((a, b) => b.price - a.price);
    } else if (sortOrder === "newest") {
      sortedProducts.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    }

    return sortedProducts;
  };

  const resetFilters = () => {
    setFilters({
      category: "all",
      priceRange: "all",
      color: "all",
      size: "all",
    });
    setSearchTerm("");
  };

  const toggleFilters = () => {
    setShowFilters(!showFilters);
    if (showSortDropdown) setShowSortDropdown(false);
  };

  const toggleSortDropdown = () => {
    setShowSortDropdown(!showSortDropdown);
  };

  const handleSortChange = (value) => {
    setSortOrder(value);
    setShowSortDropdown(false);
  };
  
  const removeFilter = (filterType) => {
    setFilters({
      ...filters,
      [filterType]: "all"
    });
  };
  
  const closeFilterPanel = () => {
    setShowFilters(false);
  };
  
  const applyFilterChanges = () => {
    setShowFilters(false);
  };

  const filteredProducts = applyFilters(data);
  const sortedProducts = applySorting(filteredProducts);
  
  // Check if any filters are active
  const hasActiveFilters = Object.values(filters).some(value => value !== "all") || searchTerm.trim() !== "";
  
  // Get filter labels for display
  const getFilterLabel = (type, value) => {
    if (value === "all") return null;
    
    switch(type) {
      case "category":
        return value.charAt(0).toUpperCase() + value.slice(1);
      case "priceRange":
        return `$${value.replace("-", " - $")}`;
      case "color":
      case "size":
        return value.toUpperCase();
      default:
        return value;
    }
  };

  return (
    <Container style={{ padding: 0 }}>
      <ShopControls>
        <ControlsGroup>
          <FilterButton onClick={toggleFilters}>
            <FaFilter /> Filters
          </FilterButton>
          
          <SortContainer ref={sortRef}>
            <SortButton active={showSortDropdown} onClick={toggleSortDropdown}>
              <span>
                <FaSort /> Sort
              </span>
              <FaChevronDown size={12} />
            </SortButton>
            
            <SortDropdown show={showSortDropdown}>
              <SortOption 
                active={sortOrder === "default"}
                onClick={() => handleSortChange("default")}
              >
                <FaSort /> Default
              </SortOption>
              <SortOption 
                active={sortOrder === "lowToHigh"}
                onClick={() => handleSortChange("lowToHigh")}
              >
                <FaSortAmountUp /> Price: Low to High
              </SortOption>
              <SortOption 
                active={sortOrder === "highToLow"}
                onClick={() => handleSortChange("highToLow")}
              >
                <FaSortAmountDown /> Price: High to Low
              </SortOption>
              <SortOption 
                active={sortOrder === "newest"}
                onClick={() => handleSortChange("newest")}
              >
                <FaSort /> Newest First
              </SortOption>
            </SortDropdown>
          </SortContainer>
        </ControlsGroup>
        
        <ProductCount>
          Showing {sortedProducts.length} products
        </ProductCount>
      </ShopControls>
      
      {/* Active Filters Display */}
      {hasActiveFilters && (
        <ActiveFilters>
          {searchTerm.trim() !== "" && (
            <FilterTag>
              Search: {searchTerm}
              <button onClick={() => setSearchTerm("")}>
                <FaTimes size={10} />
              </button>
            </FilterTag>
          )}
          
          {Object.entries(filters).map(([key, value]) => {
            const label = getFilterLabel(key, value);
            if (!label) return null;
            
            return (
              <FilterTag key={key}>
                {key === "category" ? "Category: " : 
                 key === "priceRange" ? "Price: " : 
                 key === "color" ? "Color: " : 
                 key === "size" ? "Size: " : ""}{label}
                <button onClick={() => removeFilter(key)}>
                  <FaTimes size={10} />
                </button>
              </FilterTag>
            );
          })}
          
          {hasActiveFilters && (
            <Button onClick={resetFilters}>
              Clear All
            </Button>
          )}
        </ActiveFilters>
      )}
      
      {/* Desktop Filter Section */}
      <FilterSection>
        <Row>
          <Col xs={12}>
            <SearchInput>
              <FaSearch />
              <input 
                type="text" 
                placeholder="Search products..." 
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </SearchInput>
          </Col>
          <Col xs={12} sm={6} md={3}>
            <FilterOption>
              <FilterLabel>Category:</FilterLabel>
              <FilterSelect
                as="select"
                value={filters.category}
                onChange={(e) =>
                  setFilters({ ...filters, category: e.target.value })
                }
              >
                <option value="all">All Categories</option>
                <option value="men">Tees</option>
                <option value="women">Jean</option>
                <option value="kids">Cap/hats</option>
                <option value="shirts">Shirts</option>
                <option value="socks">Socks</option>
                <option value="underwear">Underwear's</option>
                <option value="shoes">Shoes</option>
              </FilterSelect>
            </FilterOption>
          </Col>
          <Col xs={12} sm={6} md={3}>
            <FilterOption>
              <FilterLabel>Price Range:</FilterLabel>
              <FilterSelect
                as="select"
                value={filters.priceRange}
                onChange={(e) =>
                  setFilters({ ...filters, priceRange: e.target.value })
                }
              >
                <option value="all">All Prices</option>
                <option value="0-50">$0 - $50</option>
                <option value="50-100">$50 - $100</option>
                <option value="100-200">$100 - $200</option>
                <option value="200-500">$200 - $500</option>
                <option value="500-1000">$500 - $1000</option>
              </FilterSelect>
            </FilterOption>
          </Col>
          <Col xs={12} sm={6} md={3}>
            <FilterOption>
              <FilterLabel>Color:</FilterLabel>
              <FilterSelect
                as="select"
                value={filters.color}
                onChange={(e) =>
                  setFilters({ ...filters, color: e.target.value })
                }
              >
                <option value="all">All Colors</option>
                <option value="red">Red</option>
                <option value="blue">Blue</option>
                <option value="green">Green</option>
                <option value="black">Black</option>
                <option value="white">White</option>
              </FilterSelect>
            </FilterOption>
          </Col>
          <Col xs={12} sm={6} md={3}>
            <FilterOption>
              <FilterLabel>Size:</FilterLabel>
              <FilterSelect
                as="select"
                value={filters.size}
                onChange={(e) =>
                  setFilters({ ...filters, size: e.target.value })
                }
              >
                <option value="all">All Sizes</option>
                <option value="xs">XS</option>
                <option value="s">S</option>
                <option value="m">M</option>
                <option value="l">L</option>
                <option value="xl">XL</option>
              </FilterSelect>
            </FilterOption>
          </Col>
        </Row>
      </FilterSection>
      
      {/* Mobile Filter Panel */}
      <MobileFilterOverlay show={showFilters} onClick={closeFilterPanel} />
      <MobileFilterPanel show={showFilters} ref={filterRef}>
        <FilterHeader>
          <h5 style={{ margin: 0, fontWeight: '600' }}>Filter Products</h5>
          <button 
            style={{ background: 'none', border: 'none', cursor: 'pointer', fontSize: '18px' }}
            onClick={closeFilterPanel}
          >
            <FaTimes />
          </button>
        </FilterHeader>
        
        <FilterBody>
          <SearchInput>
            <FaSearch />
            <input 
              type="text" 
              placeholder="Search products..." 
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </SearchInput>
          
          <FilterOption>
            <FilterLabel>Category:</FilterLabel>
            <FilterSelect
              as="select"
              value={filters.category}
              onChange={(e) =>
                setFilters({ ...filters, category: e.target.value })
              }
            >
              <option value="all">All Categories</option>
              <option value="men">Tees</option>
              <option value="women">Jean</option>
              <option value="kids">Cap/hats</option>
              <option value="shirts">Shirts</option>
              <option value="socks">Socks</option>
              <option value="underwear">Underwear's</option>
              <option value="shoes">Shoes</option>
            </FilterSelect>
          </FilterOption>
          
          <FilterOption>
            <FilterLabel>Price Range:</FilterLabel>
            <FilterSelect
              as="select"
              value={filters.priceRange}
              onChange={(e) =>
                setFilters({ ...filters, priceRange: e.target.value })
              }
            >
              <option value="all">All Prices</option>
              <option value="0-50">$0 - $50</option>
              <option value="50-100">$50 - $100</option>
              <option value="100-200">$100 - $200</option>
              <option value="200-500">$200 - $500</option>
              <option value="500-1000">$500 - $1000</option>
            </FilterSelect>
          </FilterOption>
          
          <FilterOption>
            <FilterLabel>Color:</FilterLabel>
            <FilterSelect
              as="select"
              value={filters.color}
              onChange={(e) =>
                setFilters({ ...filters, color: e.target.value })
              }
            >
              <option value="all">All Colors</option>
              <option value="red">Red</option>
              <option value="blue">Blue</option>
              <option value="green">Green</option>
              <option value="black">Black</option>
              <option value="white">White</option>
            </FilterSelect>
          </FilterOption>
          
          <FilterOption>
            <FilterLabel>Size:</FilterLabel>
            <FilterSelect
              as="select"
              value={filters.size}
              onChange={(e) =>
                setFilters({ ...filters, size: e.target.value })
              }
            >
              <option value="all">All Sizes</option>
              <option value="xs">XS</option>
              <option value="s">S</option>
              <option value="m">M</option>
              <option value="l">L</option>
              <option value="xl">XL</option>
            </FilterSelect>
          </FilterOption>
        </FilterBody>
        
        <FilterFooter>
          <Button onClick={resetFilters}>
            Reset All
          </Button>
          <Button primary onClick={applyFilterChanges}>
            Apply Filters
          </Button>
        </FilterFooter>
      </MobileFilterPanel>
      
      <ProductList
        data={sortedProducts}
        isLoading={isLoading}
        onProductClick={handleProductClick}
      />
      {showModal && selectedProduct && (
        <ProductModal
          showModal={showModal}
          setShowModal={setShowModal}
          product={selectedProduct}
        />
      )}
    </Container>
  );
};

export default ProductPage;
