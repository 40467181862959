// ProductList.js
import React, { useEffect } from 'react';
import ProductItem from './ProductItem';
import { ProductWrapper } from './styles';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const ProductList = ({ data, onProductClick, isLoading }) => {
  useEffect(() => {
    console.log('Product data received in ProductList:', data);
  }, [data]);

  return (
    <ProductWrapper>
      {isLoading ? (
        Array.from({ length: 8 }).map((_, index) => (
          <div className="colPro" key={index}>
            <Skeleton width="100%" height={275} />
            <h2 className="h2Name">
              <Skeleton width="80%" />
            </h2>
            <div className="proPri">
              <p>
                <Skeleton width="60%" />
              </p>
            </div>
          </div>
        ))
      ) : data && data.length > 0 ? (
        data.map((product) => (
          <ProductItem
            key={product._id || product.id}
            product={product}
            onClick={() => onProductClick(product)}
          />
        ))
      ) : (
        <div className="no-products">
          <h3>No products found matching your criteria</h3>
          <p>Try adjusting your filters or search terms</p>
        </div>
      )}
    </ProductWrapper>
  );
};

export default ProductList;