import React, { useState, useEffect } from "react";
import { Provider, useDispatch } from "react-redux";
import { store } from "./redux/store/store";
import Home from "./pages/Home";
import { Routes, Route, useLocation } from "react-router-dom";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Shop from "./pages/Shop";
import View from "./pages/View";
import { CartProvider } from "./components/Cart/cartContext";
import Navbar from "./components/Navbar";
import Cart from "./pages/Cart";
import GetAllItem from "./pages/GetAllItems";
import AdminDashboard from "./pages/AdminDashboard";
import Admin from "./pages/Admin";
import Card from "./components/ItemCard/ItemCard";
import "./App.css";
import NeedHelp from "./components/NeedHelp";
import Checkout from "./pages/checkout";
import CategoryPage from "./pages/CategoryPage";
import { fetchUserRegion } from "./redux/slice/regionSlice";
import SplashScreen from "./components/SplashScreen";

// Component to initialize region detection
const RegionInitializer = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        // Fetch user's region when the app loads
        dispatch(fetchUserRegion());
    }, [dispatch]);

    return null; // This component doesn't render anything
};

const App = () => {
    const cartItemCount = 0;
    const location = useLocation();
    const excludeHeaderPaths = ["/khalid", "/admin-dashboard", "/all%20item"];
    const showHeader = !excludeHeaderPaths.includes(location.pathname);

    const [isCartModalOpen, setIsCartModalOpen] = useState(false);
    const [showSplash, setShowSplash] = useState(true);

    // Always show splash screen on page load/reload
    useEffect(() => {
        // Always show splash screen on every page load or reload
        setShowSplash(true);

        // No localStorage check needed as we want it to show every time
    }, []);

    const handleCartModalOpen = (isOpen) => {
        setIsCartModalOpen(isOpen);

        if (isOpen) {
            document.body.classList.add("modal-open");
        } else {
            document.body.classList.remove("modal-open");
        }
    };

    return ( <
        Provider store = { store } >
        <
        CartProvider > { showSplash && < SplashScreen / > } <
        div className = { `main-content ${isCartModalOpen ? "inactive" : ""}` } > {
            showHeader && ( <
                Navbar cartCount = { cartItemCount }
                onCartModalOpen = { handleCartModalOpen }
                />
            )
        }

        { showHeader && < NeedHelp / > }

        { /* Initialize region detection */ } <
        RegionInitializer / >

        <
        Routes >
        <
        Route path = "/"
        element = { < Home / > }
        />  <
        Route path = "/about"
        element = { < About / > }
        />  <
        Route path = "/contact"
        element = { < Contact / > }
        />  <
        Route path = "/shop"
        element = { < Shop / > }
        />  <
        Route path = "/view"
        element = { < View / > }
        />  <
        Route path = "/cart"
        element = { < Cart / > }
        />  <
        Route path = "/khalid"
        element = { < Admin / > }
        />  <
        Route path = "/admin-dashboard"
        element = { < AdminDashboard / > }
        />  <
        Route path = "/all item"
        element = { < GetAllItem / > }
        />  <
        Route path = "/card"
        element = { < Card / > }
        />  <
        Route path = "/checkout"
        element = { < Checkout / > }
        />  <
        Route path = "/category/:slug"
        element = { < CategoryPage / > }
        />  < /
        Routes > <
        /div> < /
        CartProvider > <
        /Provider>
    );
};

export default App;