import React from 'react';
import { useSelector } from 'react-redux';
import { selectCurrency, selectExchangeRate } from '../../redux/slice/regionSlice';

/**
 * Formats a price according to the user's selected currency
 * @param {Object} props
 * @param {number} props.amount - The price in USD
 * @param {boolean} props.showCurrency - Whether to show the currency code
 * @param {string} props.className - CSS class for styling
 */
export const PriceFormatter = ({ amount, showCurrency = false, className = '' }) => {
  const currency = useSelector(selectCurrency);
  const exchangeRate = useSelector(selectExchangeRate);
  
  // Convert the amount to the user's currency
  const convertedAmount = amount * exchangeRate;
  
  // Format the price according to the locale
  const formattedPrice = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency.code,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(convertedAmount);
  
  return (
    <span className={className}>
      {formattedPrice}
      {showCurrency && ` ${currency.code}`}
    </span>
  );
};

/**
 * Formats a price without using the Intl formatter (simpler version)
 * @param {Object} props
 * @param {number} props.amount - The price in USD
 * @param {boolean} props.showCode - Whether to show the currency code
 * @param {string} props.className - CSS class for styling
 */
export const SimplePriceFormatter = ({ amount, showCode = false, className = '' }) => {
  const currency = useSelector(selectCurrency);
  const exchangeRate = useSelector(selectExchangeRate);
  
  // Convert the amount to the user's currency
  const convertedAmount = amount * exchangeRate;
  
  // Round to 2 decimal places
  const roundedAmount = Math.round(convertedAmount * 100) / 100;
  
  // Format with thousand separators
  const formattedAmount = roundedAmount.toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  
  return (
    <span className={className}>
      {currency.symbol}{formattedAmount}
      {showCode && ` ${currency.code}`}
    </span>
  );
};

export default PriceFormatter; 