import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../api";

export const fetchFeaturedProducts = createAsyncThunk(
    "featuredProducts/fetch",
    async(_, { rejectWithValue }) => {
        try {
            const response = await axiosInstance.get("/api/v1/products/all");
            return response.data.products.filter((product) => product.isFeatured);
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

const featuredProductsSlice = createSlice({
    name: "featuredProducts",
    initialState: {
        products: [],
        status: "idle",
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchFeaturedProducts.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchFeaturedProducts.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.products = action.payload;
            })
            .addCase(fetchFeaturedProducts.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload;
            });
    },
});

export default featuredProductsSlice.reducer;