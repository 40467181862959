import React, { useState, useEffect, useRef } from 'react';

const PayPalButtonWrapper = ({ total, onApprove, onError, userDetails }) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState(null);
  const paypalButtonsRef = useRef(null);
  
  useEffect(() => {
    // Check if PayPal SDK is loaded
    if (window.paypal) {
      try {
        // Clear any existing buttons
        if (paypalButtonsRef.current) {
          paypalButtonsRef.current.innerHTML = '';
        }
        
        // Render PayPal buttons
        window.paypal.Buttons({
          // Button style
          style: {
            layout: 'vertical',
            color: 'blue',
            shape: 'rect',
            label: 'paypal'
          },
          
          // Create order
          createOrder: function(data, actions) {
            console.log('Creating PayPal order...');
            return actions.order.create({
              purchase_units: [{
                description: "Don't Brag Purchase",
                amount: {
                  value: total.toFixed(2)
                }
              }]
            });
          },
          
          // When the payment is approved
          onApprove: function(data, actions) {
            console.log('PayPal payment approved:', data);
            setIsProcessing(true);
            
            return actions.order.capture().then(function(details) {
              console.log('PayPal payment completed:', details);
              setIsProcessing(false);
              onApprove(details);
            });
          },
          
          // When there's an error
          onError: function(err) {
            console.error('PayPal error:', err);
            setError('Error processing payment: ' + (err.message || 'Unknown error'));
            onError(err);
          },
          
          // When the payment is cancelled
          onCancel: function() {
            console.log('PayPal payment cancelled');
            setError('Payment was cancelled. Please try again.');
          }
        }).render(paypalButtonsRef.current);
        
      } catch (err) {
        console.error('Error rendering PayPal buttons:', err);
        setError('Failed to initialize PayPal. Please try again later.');
      }
    } else {
      console.error('PayPal SDK not loaded');
      setError('PayPal is not available. Please try again later.');
    }
  }, [total, onApprove, onError]);
  
  return (
    <div className="paypal-wrapper">
      {error && (
        <div className="paypal-error">{error}</div>
      )}
      
      {isProcessing && (
        <div className="paypal-processing">
          Processing your payment...
        </div>
      )}
      
      <div className="paypal-sandbox-info" style={{ backgroundColor: '#e3f2fd', padding: '10px', borderRadius: '4px', marginBottom: '15px', fontSize: '12px' }}>
        <strong>Sandbox Test Mode</strong>
        <p>Use these PayPal sandbox credentials for testing:</p>
        <ul style={{ textAlign: 'left', paddingLeft: '20px' }}>
          <li><strong>Email:</strong> sb-47bfpf29049404@personal.example.com</li>
          <li><strong>Password:</strong> 12345678</li>
        </ul>
        <p>Or use any credit card with:</p>
        <ul style={{ textAlign: 'left', paddingLeft: '20px' }}>
          <li><strong>Card Number:</strong> 4111 1111 1111 1111</li>
          <li><strong>Expiry Date:</strong> Any future date</li>
          <li><strong>CVV:</strong> Any 3 digits</li>
        </ul>
      </div>
      
      <div className="paypal-troubleshooting" style={{ marginBottom: '15px', fontSize: '12px', color: '#666' }}>
        <p>If you encounter errors with PayPal:</p>
        <ol style={{ textAlign: 'left', paddingLeft: '20px' }}>
          <li>Clear your browser cookies and cache</li>
          <li>Try using a different browser</li>
          <li>Disable any ad blockers or VPN</li>
        </ol>
      </div>
      
      {/* PayPal buttons will render here */}
      <div ref={paypalButtonsRef} className="paypal-button-container"></div>
      
      <p style={{ fontSize: '12px', color: '#666', marginTop: '8px', textAlign: 'center' }}>
        Click the PayPal button above to pay securely.
      </p>
    </div>
  );
};

export default PayPalButtonWrapper; 