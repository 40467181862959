import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchFeaturedProducts } from "../redux/slice/featuredProductsSlice";
import "../styles/home.css";
import homee from "../assets/image/won.png";
import { useMediaQuery } from "react-responsive";
import Footer from "../components/Footer";
import ProductModal from "../components/Shops/ProductModal";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Link } from "react-router-dom";
import { useSwipeable } from "react-swipeable";
import { FaStar, FaStarHalf, FaRegStar, FaArrowRight } from "react-icons/fa";
import { motion } from "framer-motion";

const Home = () => {
  const dispatch = useDispatch();
  const { products: featuredProducts, status } = useSelector(
    (state) => state.featuredProducts
  );
  const isLoading = status === "loading";

  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [, setCurrentIndex] = useState(0);
  const [, setCurrentBestSellersIndex] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [newArrivalsIndex, setNewArrivalsIndex] = useState(0);
  const [bestSellersIndex, setBestSellersIndex] = useState(0);

  useEffect(() => {
    if (status === "idle") {
      dispatch(fetchFeaturedProducts());
    }
  }, [status, dispatch]);

  useEffect(() => {
    if (featuredProducts.length > 0) {
      setCurrentBestSellersIndex(featuredProducts.length - (isMobile ? 10 : 4));
    }
  }, [featuredProducts, isMobile]);

  const handleRightClick = () => {
    setCurrentIndex((prevIndex) => {
      const nextIndex = prevIndex + 1;
      return nextIndex >= featuredProducts.length ? 0 : nextIndex;
    });
  };

  const handleLeftClick = () => {
    setCurrentIndex((prevIndex) => {
      const nextIndex = prevIndex - 1;
      return nextIndex < 0 ? featuredProducts.length - 1 : nextIndex;
    });
  };

  const handleBestSellersLeftClick = () => {
    setCurrentBestSellersIndex((prevIndex) => {
      const nextIndex = prevIndex - 1;
      return nextIndex < 0 ? featuredProducts.length - 1 : nextIndex;
    });
  };

  const handleBestSellersRightClick = () => {
    setCurrentBestSellersIndex((prevIndex) => {
      const nextIndex = prevIndex + 1;
      return nextIndex >= featuredProducts.length ? 0 : nextIndex;
    });
  };

  const handleNewArrivalsSwipe = (direction) => {
    if (direction === "Left") {
      handleRightClick();
    } else if (direction === "Right") {
      handleLeftClick();
    }
  };

  const handleBestSellersSwipe = (direction) => {
    if (direction === "Left") {
      handleBestSellersRightClick();
    } else if (direction === "Right") {
      handleBestSellersLeftClick();
    }
  };

  const newArrivalsSwipeHandlers = useSwipeable({
    onSwipedLeft: () => handleNewArrivalsSwipe("Left"),
    onSwipedRight: () => handleNewArrivalsSwipe("Right"),
  });

  const bestSellersSwipeHandlers = useSwipeable({
    onSwipedLeft: () => handleBestSellersSwipe("Left"),
    onSwipedRight: () => handleBestSellersSwipe("Right"),
  });

  const handleProductClick = (product) => {
    setSelectedProduct(product);
    setIsModalOpen(true);
  };

  const handlePrevNewArrivals = () => {
    if (newArrivalsIndex > 0) {
      setNewArrivalsIndex(prev => prev - 1);
    }
  };
  
  const handleNextNewArrivals = () => {
    if (newArrivalsIndex < featuredProducts.length - (isMobile ? 6 : 8)) {
      setNewArrivalsIndex(prev => prev + 1);
    }
  };
  
  const handlePrevBestSellers = () => {
    if (bestSellersIndex > 0) {
      setBestSellersIndex(prev => prev - 1);
    }
  };
  
  const handleNextBestSellers = () => {
    if (bestSellersIndex < featuredProducts.length - (isMobile ? 6 : 8)) {
      setBestSellersIndex(prev => prev + 1);
    }
  };

  // Function to render stars based on rating
  const renderStars = (rating) => {
    const fullStars = Math.floor(rating);
    const hasHalfStar = rating % 1 >= 0.5;
    const stars = [];
    
    for (let i = 0; i < fullStars; i++) {
      stars.push(<FaStar key={`full-${i}`} />);
    }
    
    if (hasHalfStar) {
      stars.push(<FaStarHalf key="half" />);
    }
    
    const emptyStars = 5 - stars.length;
    for (let i = 0; i < emptyStars; i++) {
      stars.push(<FaRegStar key={`empty-${i}`} />);
    }
    
    return stars;
  };

  return (
    <div className="general">
      {isMobile && (
        <div className="homeMobile">
          <div className="trendy-collection-container">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
            >
              <div className="trendy-badge">Hot This Season</div>
              <h1 className="trendy-title">Trendy Collection</h1>
              <p className="trendy-subtitle">Elevate your style with our exclusive designs</p>
              <Link to="/shop">
                <button className="trendy-shop-btn">
                  <span>Explore Collection</span>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14 5L21 12M21 12L14 19M21 12H3" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                </button>
              </Link>
            </motion.div>
          </div>
        </div>
      )}
      {!isMobile && (
        <div className="home">
          <div className="homeContent">
            <div className="text">
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6 }}
              >
                <div className="trendy-badge desktop">Hot This Season</div>
                <h1>Discover Your Style</h1>
                <h2 className="trendy-highlight">Trendy Collection</h2>
                <h3>
                  Ready to refresh your wardrobe? Start shopping with us today and
                  discover a world of fashion at your fingertips!
                </h3>
                <Link to="/shop" className="desktop-shop-link">
                  <button className="trendy-shop-btn desktop">
                    <span>Explore Collection</span>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M14 5L21 12M21 12L14 19M21 12H3" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                  </button>
                </Link>
              </motion.div>
            </div>
            <div className="hoImg">
              <img src={homee} alt="" />
              <div className="floating-badge">
                <span className="badge-text">Premium Quality</span>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="product">
        <div className="proCollection">
          {/* New Arrivals Section */}
          <div className="collection-section new-arrivals-section">
            <div className="section-header-wrapper">
              <div className="section-header-content">
                <div className="section-badge pulse-animation">JUST LANDED</div>
                <h2 className="section-heading">New Arrivals</h2>
                <p className="section-description">
                  Elevate your wardrobe with our latest drops. Be the first to rock these head-turning pieces before they're gone!
                </p>
              </div>
              <div className="section-navigation">
                <button className="nav-button prev" onClick={handlePrevNewArrivals} aria-label="Previous items">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15 6L9 12L15 18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                </button>
                <button className="nav-button next" onClick={handleNextNewArrivals} aria-label="Next items">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9 6L15 12L9 18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                </button>
              </div>
            </div>
            
            <div className="products-showcase" {...newArrivalsSwipeHandlers}>
              {isLoading ? (
                <div className="products-grid skeleton-grid">
                  {Array.from({ length: isMobile ? 4 : 4 }).map((_, index) => (
                    <div className="product-card skeleton" key={index}>
                      <div className="product-image-container">
                        <Skeleton width="100%" height={isMobile ? 180 : 300} />
                      </div>
                      <div className="product-info">
                        <h3 className="product-name">
                          <Skeleton width={isMobile ? 80 : 200} />
                        </h3>
                        <div className="product-price">
                          <Skeleton width={isMobile ? 60 : 100} />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="products-grid">
                  {featuredProducts
                    .slice(newArrivalsIndex, newArrivalsIndex + (isMobile ? 6 : 8))
                    .map((product, index) => (
                      <div
                        className="product-card new-arrival"
                        key={product._id}
                        onClick={() => handleProductClick(product)}
                        style={{ animationDelay: `${index * 0.1}s` }}
                      >
                        <div className="product-tag new-tag">
                          <span className="tag-icon">✦</span> New
                        </div>
                        <div className="product-image-container">
                          <img
                            src={product.image}
                            alt={product.name}
                            className="product-image"
                          />
                          <div className="product-overlay">
                            <div className="overlay-content">
                              <div className="overlay-top">
                                <span className="product-category">{typeof product.category === 'object' ? product.category.name : product.category}</span>
                                <span className="product-status">{product.inStock ? "In Stock" : "Out of Stock"}</span>
                              </div>
                              <button className="quick-view-btn">
                                <span className="btn-text">Quick Look</span>
                                <span className="btn-icon">
                                  <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M12 15a3 3 0 100-6 3 3 0 000 6z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                  </svg>
                                </span>
                              </button>
                            </div>
                            <div className="color-options">
                              {product.colors && product.colors.map((color, idx) => (
                                <span key={idx} className="color-option" style={{backgroundColor: color}}></span>
                              ))}
                              {(!product.colors || product.colors.length === 0) && (
                                <>
                                  <span className="color-option" style={{backgroundColor: "#000"}}></span>
                                  <span className="color-option" style={{backgroundColor: "#500157"}}></span>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="product-info">
                          <h3 className="product-name">{product.name}</h3>
                          <div className="product-meta">
                            <div className="product-price">
                              ${new Intl.NumberFormat("en-NG").format(product.price)}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              )}
              <div className="section-cta">
                <Link to="/shop" className="view-more-btn">
                  <span>View All New Arrivals</span>
                  <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5 12H19" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M12 5L19 12L12 19" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                </Link>
              </div>
            </div>
          </div>
          
          {/* Trending Banner */}
          <div className="trending-banner">
            <div className="trending-content">
              <div className="limited-edition-badge">
                <span className="trending-badge">Limited Edition</span>
                <span className="edition-year">{new Date().getFullYear()}</span>
              </div>
              <h2 className="trending-title">Exclusive Collection</h2>
              <p className="trending-description">
                Discover our limited edition pieces crafted with premium materials and exceptional attention to detail. Elevate your style with these exclusive designs.
              </p>
              <Link to="/shop" className="trending-btn">
                Shop Collection
                <FaArrowRight />
              </Link>
            </div>
            <div className="trending-image">
              <div className="image-overlay"></div>
              <div className="limited-edition-marker">
                <span className="marker-text">DON'T BRAG</span>
                <span className="marker-year">{new Date().getFullYear()}</span>
              </div>
            </div>
          </div>
          
          {/* Best Sellers Section */}
          <div className="collection-section bestsellers-section">
            <div className="section-header-wrapper">
              <div className="section-header-content">
                <div className="section-badge gold">Most Popular</div>
                <h2 className="section-heading">Best Sellers</h2>
                <p className="section-description">
                  Our most loved pieces that have stood the test of time and style
                </p>
              </div>
              <div className="section-navigation">
                <button className="nav-button prev" onClick={handlePrevBestSellers} aria-label="Previous items">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15 6L9 12L15 18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                </button>
                <button className="nav-button next" onClick={handleNextBestSellers} aria-label="Next items">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9 6L15 12L9 18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                </button>
              </div>
            </div>
            
            <div className="products-showcase" {...bestSellersSwipeHandlers}>
              {isLoading ? (
                <div className="products-grid skeleton-grid">
                  {Array.from({ length: isMobile ? 4 : 4 }).map((_, index) => (
                    <div className="product-card skeleton" key={index}>
                      <div className="product-image-container">
                        <Skeleton width="100%" height={isMobile ? 180 : 300} />
                      </div>
                      <div className="product-info">
                        <h3 className="product-name">
                          <Skeleton width={isMobile ? 80 : 200} />
                        </h3>
                        <div className="product-price">
                          <Skeleton width={isMobile ? 60 : 100} />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="products-grid">
                  {featuredProducts
                    .slice(bestSellersIndex, bestSellersIndex + (isMobile ? 6 : 8))
                    .map((product, index) => (
                      <div
                        className="product-card bestseller"
                        key={product._id}
                        onClick={() => handleProductClick(product)}
                        style={{ animationDelay: `${index * 0.1}s` }}
                      >
                        <div className="product-badge">
                          <span className="badge-icon">★</span> Popular
                        </div>
                        <div className="product-image-container">
                          <img
                            src={product.image}
                            alt={product.name}
                            className="product-image"
                          />
                          <div className="product-overlay">
                            <div className="overlay-content">
                              <div className="overlay-top">
                                <span className="product-category">{typeof product.category === 'object' ? product.category.name : product.category}</span>
                                <span className="product-status">{product.inStock ? "In Stock" : "Out of Stock"}</span>
                              </div>
                              <button className="quick-view-btn">
                                <span className="btn-text">Quick Look</span>
                                <span className="btn-icon">
                                  <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M12 15a3 3 0 100-6 3 3 0 000 6z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                  </svg>
                                </span>
                              </button>
                            </div>
                            <div className="color-options">
                              {product.colors && product.colors.map((color, idx) => (
                                <span key={idx} className="color-option" style={{backgroundColor: color}}></span>
                              ))}
                              {(!product.colors || product.colors.length === 0) && (
                                <>
                                  <span className="color-option" style={{backgroundColor: "#000"}}></span>
                                  <span className="color-option" style={{backgroundColor: "#500157"}}></span>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="product-info">
                          <h3 className="product-name">{product.name}</h3>
                          <div className="product-meta">
                            <div className="product-price">
                              ${new Intl.NumberFormat("en-NG").format(product.price)}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              )}
              <div className="section-cta">
                <Link to="/shop" className="view-more-btn gold">
                  <span>View All Best Sellers</span>
                  <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5 12H19" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M12 5L19 12L12 19" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                </Link>
              </div>
            </div>
          </div>
        </div>
        {isModalOpen && selectedProduct && (
          <ProductModal
            showModal={isModalOpen}
            setShowModal={setIsModalOpen}
            product={selectedProduct}
          />
        )}
      </div>

      {isMobile && (
        <div className="natureReady">
          <h4> Ready to refresh your wardrobe?</h4>
          <p>
            Elevate your style, embrace comfort, and make a statement with our
            service
          </p>
          <div className="natureRed">
            <Link to="/shop">
              <button className="shopfor">Shop Now!</button>
            </Link>
          </div>
        </div>
      )}

      <Footer />
    </div>
  );
};

export default Home;
