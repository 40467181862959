import React from 'react';
import { useSelector } from 'react-redux';
import { selectRegion, selectShippingCost } from '../../redux/slice/regionSlice';
import { PriceFormatter } from './PriceFormatter';

/**
 * Displays shipping information based on the user's region
 * @param {Object} props
 * @param {string} props.className - CSS class for styling
 */
export const ShippingInfo = ({ className = '' }) => {
  const { countryCode, region, shippingDiscountThreshold, shippingDiscountPercent } = useSelector(selectRegion);
  const shippingCost = useSelector(selectShippingCost);
  
  // Get region name for display
  const getRegionName = (regionCode) => {
    const regionNames = {
      africa: 'Africa',
      default: 'International',
    };
    return regionNames[regionCode] || 'International';
  };
  
  return (
    <div className={className}>
      <p>Shipping to: <strong>{countryCode}</strong> ({getRegionName(region)})</p>
      <p>Shipping cost: <PriceFormatter amount={shippingCost || 10} /></p>
      {shippingDiscountThreshold > 0 && (
        <p>Get {shippingDiscountPercent}% off shipping on orders over <PriceFormatter amount={shippingDiscountThreshold} /></p>
      )}
    </div>
  );
};

/**
 * Displays a simple shipping cost
 * @param {Object} props
 * @param {number} props.subtotal - The current cart subtotal
 * @param {string} props.className - CSS class for styling
 */
export const ShippingCost = ({ subtotal = 0, className = '' }) => {
  const { shippingDiscountThreshold, shippingDiscountPercent, shippingCost } = useSelector(selectRegion);
  
  // Ensure shipping cost is a valid number
  const baseShippingCost = typeof shippingCost === 'number' && !isNaN(shippingCost) ? shippingCost : 10;
  
  // Check if eligible for shipping discount
  const isDiscounted = subtotal >= shippingDiscountThreshold;
  
  // Calculate the shipping cost with discount if applicable
  const finalShippingCost = isDiscounted 
    ? baseShippingCost * (1 - shippingDiscountPercent / 100) 
    : baseShippingCost;
  
  return (
    <div className={className}>
      {isDiscounted ? (
        <div>
          <span style={{ textDecoration: 'line-through', color: '#999', marginRight: '5px' }}>
            <PriceFormatter amount={baseShippingCost} />
          </span>
          <PriceFormatter amount={finalShippingCost} />
          <div style={{ fontSize: '0.8em', color: '#500157' }}>
            {shippingDiscountPercent}% off
          </div>
        </div>
      ) : (
        <PriceFormatter amount={baseShippingCost} />
      )}
    </div>
  );
};

export default ShippingInfo; 