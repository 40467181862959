import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import '../styles/splash.css';
import logo from "../assets/image/log.png";

const SplashScreen = () => {
  const [showSplash, setShowSplash] = useState(true);

  useEffect(() => {
    // Hide splash screen after 3.5 seconds
    const timer = setTimeout(() => {
      setShowSplash(false);
    }, 3500);

    return () => {
      clearTimeout(timer);
      // Reset state when component unmounts to ensure it shows again on next load
      setShowSplash(true);
    };
  }, []);

  return (
    <AnimatePresence>
      {showSplash && (
        <motion.div 
          className="splash-screen"
          initial={{ y: '100%' }}
          animate={{ y: 0 }}
          exit={{ 
            y: '-100%',
            transition: { duration: 0.6, ease: [0.65, 0, 0.35, 1] }
          }}
          transition={{ 
            duration: 0.7, 
            ease: [0.22, 1, 0.36, 1]
          }}
        >
          <div className="splash-content">
            <motion.div 
              className="splash-logo-container"
              initial={{ scale: 0.8, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              transition={{ delay: 0.3, duration: 0.5 }}
            >
              <img src={logo} alt="Don't Brag Logo" className="splash-logo" />
            </motion.div>
            
            <motion.h1 
              className="splash-title"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.5, duration: 0.5 }}
            >
              DON'T BRAG
            </motion.h1>
            
            <motion.p 
              className="splash-tagline"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.7, duration: 0.5 }}
            >
              Fashion that speaks for itself
            </motion.p>
            
            <motion.div 
              className="splash-loader"
              initial={{ width: 0 }}
              animate={{ width: '100%' }}
              transition={{ delay: 0.9, duration: 2 }}
            />
          </div>
          
          <motion.div 
            className="splash-pattern"
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.05 }}
            transition={{ delay: 0.2, duration: 1 }}
          />
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default SplashScreen; 