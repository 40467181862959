import React, { useState } from 'react';

const StripeButtonWrapper = ({ total, onApprove, onError, userDetails }) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState(null);
  
  const handleStripeClick = () => {
    try {
      setIsProcessing(true);
      setError(null);
      
      console.log('Starting Stripe payment process...');
      
      // Format the amount with 2 decimal places
      const amount = total.toFixed(2);
      
      // In a real implementation, you would:
      // 1. Create a payment intent on your server
      // 2. Open Stripe Checkout with the client secret
      // 3. Handle the result
      
      // For now, we'll simulate a successful payment
      setTimeout(() => {
        console.log('Simulating successful Stripe payment');
        
        // Create a mock payment details object
        const paymentDetails = {
          id: 'STRIPE-' + Date.now(),
          status: 'COMPLETED',
          payer: {
            email_address: userDetails?.email || 'customer@example.com',
            name: {
              given_name: userDetails?.firstName || 'Customer',
              surname: userDetails?.lastName || 'Name'
            }
          },
          purchase_units: [{
            amount: {
              value: amount
            }
          }]
        };
        
        // Call the onApprove function with the mock details
        onApprove(paymentDetails);
        setIsProcessing(false);
      }, 2000);
      
    } catch (err) {
      console.error('Stripe Error:', err);
      setError('Error initiating Stripe payment: ' + (err.message || 'Unknown error'));
      setIsProcessing(false);
      onError(err);
    }
  };

  return (
    <div className="stripe-wrapper">
      {error && (
        <div className="stripe-error">{error}</div>
      )}
      
      <button 
        className="stripe-button" 
        onClick={handleStripeClick}
        disabled={isProcessing}
      >
        {isProcessing ? (
          'Processing Payment...'
        ) : (
          <>
            <img 
              src="https://js.stripe.com/v3/fingerprinted/img/visa-729c05c240c4bdb47b03ac81d9945bfe.svg" 
              alt="Visa" 
              style={{ height: '20px', marginRight: '5px' }}
            />
            <img 
              src="https://js.stripe.com/v3/fingerprinted/img/mastercard-4d8844094130711885b5e41b28c9848f.svg" 
              alt="Mastercard" 
              style={{ height: '20px', marginRight: '5px' }}
            />
            Pay with Card
          </>
        )}
      </button>
      
      <p style={{ fontSize: '12px', color: '#666', marginTop: '8px', textAlign: 'center' }}>
        Click the button above to pay with your credit or debit card.
      </p>
      
      <p style={{ fontSize: '12px', color: '#d63031', marginTop: '5px', textAlign: 'center' }}>
        Note: This is a test implementation. For production, you need to integrate with Stripe's API.
      </p>
    </div>
  );
};

export default StripeButtonWrapper; 