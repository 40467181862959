import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../styles/footer.css";
import { FaFacebook, FaTwitter, FaInstagram, FaWhatsapp, FaEnvelope, FaArrowRight, FaHeart } from "react-icons/fa";
import { MiniRegionSelector } from "./utils/RegionSelector";

const Footer = () => {
  const whatsappNumber = "+2348137816749";
  const [email, setEmail] = useState("");
  const [subscribed, setSubscribed] = useState(false);

  const handleSubscribe = (e) => {
    e.preventDefault();
    if (email && email.includes('@')) {
      setSubscribed(true);
      setEmail("");
      setTimeout(() => setSubscribed(false), 3000);
    }
  };

  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-top">
          <div className="footer-brand">
            <h2 className="brand-name">DON'T BRAG</h2>
            <p className="brand-tagline">Elevate Your Style, Quietly Confident</p>
            <div className="social-links">
              <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" aria-label="Facebook">
                <FaFacebook />
              </a>
              <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" aria-label="Twitter">
                <FaTwitter />
              </a>
              <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" aria-label="Instagram">
                <FaInstagram />
              </a>
              <a href={`https://wa.me/${whatsappNumber}`} target="_blank" rel="noopener noreferrer" aria-label="WhatsApp">
                <FaWhatsapp />
              </a>
            </div>
          </div>

          <div className="footer-links">
            <div className="link-group">
              <h3>Shop</h3>
              <ul>
                <li><Link to="/shop">All Products</Link></li>
                <li><Link to="/category/new-arrivals">New Arrivals</Link></li>
                <li><Link to="/category/best-sellers">Best Sellers</Link></li>
                <li><Link to="/category/sale">Sale</Link></li>
              </ul>
            </div>

            <div className="link-group">
              <h3>Company</h3>
              <ul>
                <li><Link to="/about">About Us</Link></li>
                <li><Link to="/contact">Contact</Link></li>
                <li><Link to={`https://wa.me/${whatsappNumber}`}>Help</Link></li>
                <li><Link to="/careers">Careers</Link></li>
              </ul>
            </div>

            <div className="link-group">
              <h3>Customer Care</h3>
              <ul>
                <li><Link to="/shipping">Shipping</Link></li>
                <li><Link to="/returns">Returns & Exchanges</Link></li>
                <li><Link to="/faq">FAQ</Link></li>
                <li><Link to="/privacy">Privacy Policy</Link></li>
              </ul>
            </div>
          </div>

          <div className="newsletter">
            <h3>Stay Updated</h3>
            <p>Subscribe to get special offers, free giveaways, and new arrivals.</p>
            
            <form onSubmit={handleSubscribe} className="newsletter-form">
              <div className="input-group">
                <FaEnvelope className="input-icon" />
                <input 
                  type="email" 
                  placeholder="Your email address" 
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <button type="submit" className="subscribe-btn">
                  <FaArrowRight />
                </button>
              </div>
              {subscribed && <p className="success-message">Thank you for subscribing!</p>}
            </form>
            
            <div className="currency-selector">
              <p>Currency:</p>
              <MiniRegionSelector />
            </div>
          </div>
        </div>
      </div>
      
      <div className="footer-bottom">
        <div className="footer-container">
          <p className="copyright">
            &copy; {new Date().getFullYear()} Don't Brag. All Rights Reserved. Made with <FaHeart className="heart-icon" /> in Nigeria
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
