import styled from "styled-components";
import { Card } from "react-bootstrap";

export const ProductContainer = styled.div `
  padding-top: 97px;
  margin: 0 48px;
`;

export const ProductHeader = styled.div `
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ProductWrapper = styled.div `
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  padding: 0;
  margin-bottom: 40px;

  @media (max-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 480px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
  
  .no-products {
    grid-column: 1 / -1;
    text-align: center;
    padding: 40px 0;
    
    h3 {
      color: #500157;
      margin-bottom: 10px;
      font-size: 1.2rem;
    }
    
    p {
      color: #666;
      font-size: 0.9rem;
    }
  }
`;

export const Text = styled.p `
  margin: 0;
`;

export const TextBold = styled.p `
  margin: 0;
  font-weight: 500;
`;

export const ProductCard = styled(Card)
`
  width: 437px;
  height: 656px;
  border: none;
  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const ProductImage = styled(Card.Img)
`
  height: 584px;
  width: 437px;
  padding: 3px;
  transition: transform 0.3s ease-in-out;
  object-fit: contain;

  &:hover {
    transform: scale(1.05); // Scale up image by 10% on hover
  }
  @media (max-width: 600px) {
    width: 100%;
    height: auto;
    object-fit: contain;
  }
`;

export const ProductName = styled(Card.Title)
`
  font-size: 1.5rem;
  margin: 0.5rem 0;
  text-align: center;
`;

export const ProductItemBottom = styled.div `
  padding: 12px;
  background-color: #fff;
`;

export const productNameShop = styled.div `
 text-align: center;
`;

export const ProductDescription = styled(Card.Text)
`
  font-size: 1rem;
  margin: 0.5rem 0;
`;

export const ProductPrice = styled(Card.Text)
`
  font-size: 1.25rem;
  margin: 0.5rem 0;
  font-weight: bold;
`;

export const ProductDetails = styled(Card.Body)
`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
`;

export const ProductImageWrapper = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  background-color: #f8f9fa;

  .original-image {
    width: 100%;
    transition: opacity 0.3s;
  }

  .hover-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    transition: opacity 0.3s;
  }

  .icon-wrapper {
    position: absolute;
    bottom: 10px;
    right: 10px;
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 30%;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.3s;
  }

  &:hover {
    .original-image {
      opacity: 0;
    }

    .hover-image {
      opacity: 1;
    }

    .icon-wrapper {
      opacity: 1;
    }
  }
`;