/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/iframe-has-title */
// Navbar.js
import React, { useEffect, useState } from "react";
import logo from "../assets/image/log.png";
import "../styles/nav.css";
import { FaBars, FaTimes, FaCartPlus } from "react-icons/fa";
import { useCart } from "./Cart/cartContext";
import { Link } from "react-router-dom";
import axiosInstance from "../redux/api"; // Import the axios instance

const SideModal = ({ isOpen, onClose }) => {
  const { cart } = useCart();
  const [categories, setCategories] = useState([]);
  const [activeCategory, setActiveCategory] = useState(null);

  const fallbackCategories = [
    "Bags",
    "Tees",
    "Jean",
    "Caps/Hats",
    "Shirt",
    "Socks",
    "Underwear",
    "Shoes",
  ];

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await axiosInstance.get("/api/v1/categories/");
      setCategories(response.data);
    } catch (error) {
      console.error("Error fetching categories:", error);
      setCategories(fallbackCategories);
    }
  };

  // Handle category hover/selection
  const handleCategoryHover = (categoryId) => {
    setActiveCategory(categoryId);
  };

  return (
    <>
      <div className={`overlay ${isOpen ? "open" : ""}`} onClick={onClose}></div>
      <div className={`side-modal ${isOpen ? "open" : ""}`}>
        <div className="side-modal-content">
          <div className="side-modal-header">
            <img src={logo} alt="Logo" className="logoSide" />
            <button className="close-btn" onClick={onClose}>
              <FaTimes />
            </button>
          </div>
          
          <div className="sidebar-divider"></div>
          
          <Link to="/cart" className="cart-info" onClick={onClose}>
            <div className="cart-info-content">
              <span className="cart-label">My Cart</span>
              <span className="cart-count">{cart.length}</span>
            </div>
            <FaCartPlus className="cart-icon" />
          </Link>
          
          <div className="sidebar-divider"></div>
          
          <div className="sidebar-section">
            <h3 className="sidebar-title">Categories</h3>
            <div className="sideTextContainer">
              {categories.length > 0 ? (
                categories.map((category) => (
                  <Link
                    key={category._id}
                    to={`/category/${
                      category.slug ||
                      (category.name
                        ? category.name.toLowerCase().replace(/\s+/g, "-")
                        : "")
                    }`}
                    onClick={onClose}
                    className={`sideText ${activeCategory === category._id ? 'active' : ''}`}
                    onMouseEnter={() => handleCategoryHover(category._id)}
                    onMouseLeave={() => handleCategoryHover(null)}
                  >
                    <span className="category-name">{category.name || ""}</span>
                    <span className="category-arrow">›</span>
                  </Link>
                ))
              ) : (
                <p className="no-categories">No categories available</p>
              )}
            </div>
          </div>
          
          <div className="sidebar-divider"></div>
          
          <div className="sidebar-section">
            <h3 className="sidebar-title">Quick Links</h3>
            <div className="quick-links">
              <Link to="/" onClick={onClose} className="quick-link">
                Home
              </Link>
              <Link to="/about" onClick={onClose} className="quick-link">
                About Us
              </Link>
              <Link to="/contact" onClick={onClose} className="quick-link">
                Contact
              </Link>
            </div>
          </div>
          
          <div className="sidebar-footer">
            <p>DON'T BRAG</p>
            <p className="sidebar-tagline">Fashion that speaks for itself</p>
          </div>
        </div>
      </div>
    </>
  );
};

const CartModal = ({ isOpen, onClose }) => {
  const { cart, setCart, selectedSize } = useCart();
  const [showFeatured, setShowFeatured] = useState(false);
  const subtotal = cart.reduce(
    (acc, curr) => acc + curr.product.price * curr.quantity,
    0
  );

  // Featured items data
  const featuredItems = [
    {
      id: 'featured1',
      name: 'Premium Tee',
      price: 45.99,
      image: 'https://images.unsplash.com/photo-1576566588028-4147f3842f27?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=764&q=80'
    },
    {
      id: 'featured2',
      name: 'Designer Cap',
      price: 29.99,
      image: 'https://images.unsplash.com/photo-1588850561407-ed78c282e89b?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1036&q=80'
    },
    {
      id: 'featured3',
      name: 'Statement Bag',
      price: 79.99,
      image: 'https://images.unsplash.com/photo-1584917865442-de89df76afd3?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=870&q=80'
    }
  ];

  const handleViewFeatured = () => {
    setShowFeatured(true);
  };

  const handleBackToEmpty = () => {
    setShowFeatured(false);
  };

  const incrementQuantity = (productId) => {
    const newCart = cart.map((item) =>
      item.product.id === productId
        ? { ...item, quantity: item.quantity + 1 }
        : item
    );
    setCart(newCart);
  };

  const decrementQuantity = (productId) => {
    const newCart = cart.map((item) =>
      item.product.id === productId && item.quantity > 1
        ? { ...item, quantity: item.quantity - 1 }
        : item
    );
    setCart(newCart);
  };

  const removeFromCart = (productId) => {
    const newCart = cart.filter((item) => item.product.id !== productId);
    setCart(newCart);
  };

  return (
    <div className={`cartModal ${isOpen ? "open" : ""}`}>
      <div className="cartModalContent">
        <div className="cartModalHeader">
          <button className="close-btn cart-close-btn" onClick={onClose}>
            <FaTimes color="#500157" />
          </button>
          {cart.length === 0 ? (
            <h5 className="mycart">Shopping Bag</h5>
          ) : (
            <>
              <h5 className="mycart">My Cart</h5>
              <p className="mtcartlen"> {cart.length} Item(s)</p>
            </>
          )}
        </div>
        <div className="cartItemsWrapper">
          {cart.length === 0 ? (
            showFeatured ? (
              <div className="featuredItems">
                <div className="featuredItems-header">
                  <button className="back-button" onClick={handleBackToEmpty}>
                    ← Back
                  </button>
                  <h3 className="featuredItems-title">Featured Items</h3>
                </div>
                <div className="featuredItems-grid">
                  {featuredItems.map(item => (
                    <div key={item.id} className="featuredItem">
                      <div className="featuredItem-image">
                        <img src={item.image} alt={item.name} />
                      </div>
                      <div className="featuredItem-info">
                        <h4>{item.name}</h4>
                        <p className="featuredItem-price">${item.price.toFixed(2)}</p>
                        <Link to={`/product/${item.id}`} className="view-product-btn" onClick={onClose}>
                          View Product
                        </Link>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <div className="emptyCart">
                <div className="emptyCart-icon">
                  <iframe src="https://lottie.host/embed/aa738e8a-57bd-4d55-a452-99bc37cdd0e5/rFpQfBxcRM.json"></iframe>
                </div>
                <h3 className="emptyCart-title">Your bag is empty!</h3>
                <p className="emptyCart-message">
                  Looks like you haven't added anything to your bag yet.
                  Discover our exclusive collection and find something that speaks to you.
                </p>
                <div className="emptyCart-actions">
                  <Link to="/shop" className="emptyCart-shop-btn" onClick={onClose}>
                    Explore Collection
                  </Link>
                </div>
              </div>
            )
          ) : (
            <>
              <div className="cartItemsContainer">
                {cart.map((item, index) => (
                  <div key={index} className="cartItem">
                    <img src={item.product.image} alt={item.product.name} />
                    <div>
                      <div className="productTop">
                        <p className="productName">{item.product.name}</p>
                        <p
                          className="removeButton"
                          onClick={() => removeFromCart(item.product.id)}
                        >
                          x
                        </p>
                      </div>

                      <div className="cartItemBottom">
                        <div className="quantityButtons">
                          <p
                            className="quantityButton"
                            onClick={() => decrementQuantity(item.product.id)}
                          >
                            -
                          </p>
                          <p className="quantity"> {item.quantity}</p>
                          <p
                            className="quantityButton"
                            onClick={() => incrementQuantity(item.product.id)}
                          >
                            +
                          </p>
                        </div>

                        <div>
                          <p className="productPrice">
                            $
                            {new Intl.NumberFormat("en-NG").format(
                              item.product.price * item.quantity
                            )}
                          </p>

                          <p className="selectedSize">
                            Size: {selectedSize[item.product.id] || "N/A"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div>
                <div className="buttomCart">
                  <span className="cartTotal">
                    <p className="subtotal">Subtotal:</p>
                    <p className="total">
                      {" "}
                      ${new Intl.NumberFormat("en-NG").format(subtotal)}
                    </p>
                  </span>

                  <span className="shipping">
                    <p className="shiptext">Shipping: </p>
                    <p className="total">Calculated at checkout</p>
                  </span>

                  <span className="shipping">
                    <p className="shiptext">Total: </p>
                    <p className="total">
                      {" "}
                      ${new Intl.NumberFormat("en-NG").format(subtotal)}
                    </p>
                  </span>
                </div>
                <div className="checkoutButton">
                  <Link to="/cart">
                    <p style={{ textDecoration: "none" }} onClick={onClose}>
                      Proceed to Checkout
                    </p>
                  </Link>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const Navbar = () => {
  const { cart } = useCart();
  const [toggle, setToggle] = useState(false);
  const [isSideModalOpen, setIsSideModalOpen] = useState(false);
  const [isCartModalOpen, setIsCartModalOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 760);
    };

    handleResize(); // Check on initial render
    window.addEventListener("resize", handleResize); // Add event listener for window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up the event listener on component unmount
    };
  }, []);

  const handleToggle = () => {
    setToggle(!toggle);
    setIsSideModalOpen(!isSideModalOpen);
  };

  const handleLinkClick = () => {
    if (toggle) {
      handleToggle();
    }
  };

  const handleCartClick = () => {
    setIsCartModalOpen(true);
  };

  const handleCartOverlayClick = (event) => {
    if (event.target === event.currentTarget) {
      setIsCartModalOpen(false);
    }
  };

  return (
    <div className={`${toggle ? "activeNav" : "nav"}`}>
      <div className="mobileCon">
        {isMobile && (
          <>
            <button className="cart-btn" onClick={handleCartClick}>
              <FaCartPlus color="#500157" fontSize={25} />
              <span>{cart.length}</span>
            </button>
            <div>
              <Link to="/" className="dt">
                <span>Dont </span>Brag
              </Link>
            </div>
            <button className="nav-btn nav-close-btn" onClick={handleToggle}>
              {!toggle ? <FaBars fontSize={25} /> : <FaTimes fontSize={25} />}
            </button>
          </>
        )}
      </div>

      <img src={logo} alt="Logo" className="logo" />
      {!isMobile && (
        <ul className={`${toggle ? "list din open" : "list din"}`}>
          <li>
            <Link to="/" onClick={handleLinkClick}>
              Home
            </Link>
          </li>
          <li>
            <Link to="/shop" onClick={handleLinkClick}>
              Shop
            </Link>
          </li>
          <li>
            <Link to="/about" onClick={handleLinkClick}>
              About
            </Link>
          </li>
          <li>
            <Link to="/contact" onClick={handleLinkClick}>
              Contact
            </Link>
          </li>
        </ul>
      )}
      <div className={`${toggle ? "cartStyles open" : "cartStyles"}`}>
        <button
          onClick={handleCartClick}
          className={`${toggle ? "cartStyles open" : "cartStyles"}`}
        >
          CART: {cart.length}
        </button>
      </div>
      <div
        className={`overlay ${isSideModalOpen ? "open" : ""}`}
        onClick={handleToggle}
      ></div>
      <div
        className={`overlay ${isCartModalOpen ? "open" : ""}`}
        onClick={handleCartOverlayClick}
      ></div>
      <SideModal isOpen={isSideModalOpen} onClose={handleToggle} />
      <CartModal
        isOpen={isCartModalOpen}
        onClose={() => setIsCartModalOpen(false)}
      />
    </div>
  );
};

export default Navbar;
