import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useCart } from "../Cart/cartContext";
import { useNavigate } from "react-router-dom";
import styles from "../../styles/productModal.module.css";
import { FaShoppingBasket, FaTimes, FaHeart, FaShare, FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { motion } from "framer-motion";

const ProductModal = ({ showModal, setShowModal, product }) => {
  const { cart, setCart, selectedSize, handleSizeSelect, addToCart } = useCart();
  const navigate = useNavigate();
  const [hasAdded, setHasAdded] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [currentProduct, setCurrentProduct] = useState(product);
  const [activeTab, setActiveTab] = useState("description");
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const expectedSizes = ["XS", "S", "M", "L", "XL", "XXL"];

  const { products: relatedProducts, status } = useSelector(
    (state) => state.featuredProducts
  );
  const [shuffledRelatedProducts, setShuffledRelatedProducts] = useState([]);

  const modalBodyRef = useRef(null);
  const modalRef = useRef(null);
  const sizeErrorRef = useRef(null);
  
  // Define handleCloseModal before it's used in useEffect
  const handleCloseModal = () => {
    setShowModal(false);
    setHasAdded(false);
    // Re-enable body scrolling when modal closes
    document.body.style.overflow = "auto";
  };

  // Check if device is mobile
  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth <= 576);
    };
    
    checkIfMobile();
    window.addEventListener('resize', checkIfMobile);
    
    return () => {
      window.removeEventListener('resize', checkIfMobile);
    };
  }, []);

  // Disable body scrolling when modal is open
  useEffect(() => {
    if (showModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [showModal]);

  // Close modal when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        handleCloseModal();
      }
    };

    if (showModal) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showModal]);

  // Handle escape key press
  useEffect(() => {
    const handleEscapeKey = (event) => {
      if (event.key === "Escape") {
        handleCloseModal();
      }
    };

    if (showModal) {
      document.addEventListener("keydown", handleEscapeKey);
    }
    return () => {
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, [showModal, handleCloseModal]);

  useEffect(() => {
    if (relatedProducts.length > 0) {
      const shuffled = shuffleArray(relatedProducts);
      setShuffledRelatedProducts(shuffled);
    } else {
      setShuffledRelatedProducts([]);
    }
  }, [relatedProducts]);

  const scrollToTop = () => {
    if (modalBodyRef.current) {
      modalBodyRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const shuffleArray = (array) => {
    const shuffledArray = [...array];
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [
        shuffledArray[j],
        shuffledArray[i],
      ];
    }
    return shuffledArray;
  };

  const handleProductChange = (newProduct) => {
    setCurrentProduct(newProduct);
    setHasAdded(false);
    setQuantity(1);
    setCurrentImageIndex(0);
    scrollToTop();
  };

  const handleAddToCart = () => {
    if (!selectedSize[currentProduct.id]) {
      // Show size selection error
      if (sizeErrorRef.current) {
        sizeErrorRef.current.classList.add(styles.showError);
        setTimeout(() => {
          if (sizeErrorRef.current) {
            sizeErrorRef.current.classList.remove(styles.showError);
          }
        }, 3000);
      }
      return;
    }
    
    addToCart(currentProduct, quantity, selectedSize[currentProduct.id]);
    setHasAdded(true);
    
    // Show success message
    try {
      const successMessage = document.createElement('div');
      successMessage.className = styles.successMessage;
      successMessage.innerHTML = `
        <div class="${styles.successContent}">
          <div class="${styles.successIcon}">✓</div>
          <div class="${styles.successText}">Added to cart!</div>
        </div>
      `;
      
      if (document.body) {
        document.body.appendChild(successMessage);
        
        setTimeout(() => {
          try {
            if (document.body && document.body.contains(successMessage)) {
              document.body.removeChild(successMessage);
            }
          } catch (error) {
            console.error("Error removing success message:", error);
          }
        }, 2000);
      }
    } catch (error) {
      console.error("Error showing success message:", error);
    }
  };

  const removeFromCart = () => {
    setCart((currentCart) =>
      currentCart.filter((item) => item.product.id !== currentProduct.id)
    );
    setHasAdded(false);
  };

  const handleGoToCart = () => {
    setShowModal(false);
    navigate("/cart");
  };

  const incrementQuantity = () => {
    setQuantity(prev => prev + 1);
  };

  const decrementQuantity = () => {
    setQuantity(prev => (prev > 1 ? prev - 1 : 1));
  };
  
  const nextImage = () => {
    // Assuming currentProduct.images is an array of image URLs
    // If not, we'll just use the single image
    const images = currentProduct.images || [currentProduct.image];
    setCurrentImageIndex((prevIndex) => 
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };
  
  const prevImage = () => {
    const images = currentProduct.images || [currentProduct.image];
    setCurrentImageIndex((prevIndex) => 
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  if (!showModal) return null;

  // Get current image
  const images = currentProduct.images || [currentProduct.image];
  const currentImage = images[currentImageIndex];
  const hasMultipleImages = images.length > 1;

  // Share functionality
  const handleShare = () => {
    if (navigator.share) {
      navigator.share({
        title: currentProduct.name,
        text: `Check out this product: ${currentProduct.name}`,
        url: window.location.href,
      })
      .catch((error) => console.log('Error sharing:', error));
    } else {
      // Fallback for browsers that don't support the Web Share API
      const shareUrl = `${window.location.origin}/product/${currentProduct.id}`;
      navigator.clipboard.writeText(shareUrl)
        .then(() => {
          alert('Product link copied to clipboard!');
        })
        .catch((err) => {
          console.error('Could not copy text: ', err);
        });
    }
  };

  return (
    <div className={styles.modalOverlay}>
      <motion.div 
        className={styles.modalContainer}
        ref={modalRef}
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.9 }}
        transition={{ 
          type: "spring",
          stiffness: 300,
          damping: 30,
          duration: 0.3 
        }}
      >
        <div className={styles.modalHeader}>
          <h2 className={styles.modalTitle}>{currentProduct.name}</h2>
          <button className={styles.closeButton} onClick={handleCloseModal}>
            <FaTimes />
          </button>
        </div>
        
        <div className={styles.modalBody} ref={modalBodyRef}>
          <div className={styles.productGrid}>
            <div className={styles.productImageContainer}>
              <div className={styles.imageNavigation}>
                <div className={styles.mainImageWrapper}>
                  <img
                    src={currentImage}
              alt={currentProduct.name}
              className={styles.productImage}
            />
                  <button 
                    className={`${styles.imageNavButton} ${styles.prevButton} ${!hasMultipleImages ? styles.disabledButton : ''}`} 
                    onClick={prevImage}
                    disabled={!hasMultipleImages}
                  >
                    <FaChevronLeft />
                  </button>
                  <button 
                    className={`${styles.imageNavButton} ${styles.nextButton} ${!hasMultipleImages ? styles.disabledButton : ''}`} 
                    onClick={nextImage}
                    disabled={!hasMultipleImages}
                  >
                    <FaChevronRight />
                  </button>
                </div>
                
                {hasMultipleImages && (
                  <div className={styles.thumbnailContainer}>
                    {images.map((img, index) => (
                      <div 
                        key={index}
                        className={`${styles.thumbnail} ${index === currentImageIndex ? styles.activeThumbnail : ''}`}
                        onClick={() => setCurrentImageIndex(index)}
                      >
                        <img src={img} alt={`Thumbnail ${index + 1}`} />
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
            
            <div className={styles.productDetails}>
              <div className={styles.productInfo}>
                <div className={styles.priceContainer}>
                  <span className={styles.price}>
                    ${new Intl.NumberFormat("en-US").format(currentProduct.price)}
                  </span>
                  {currentProduct.originalPrice && (
                    <span className={styles.originalPrice}>
                      ${new Intl.NumberFormat("en-US").format(currentProduct.originalPrice)}
                    </span>
                  )}
                </div>
                
                <div className={styles.productActions}>
                  <button className={styles.actionButton} onClick={handleShare}>
                    <FaShare /> Share
                  </button>
                </div>
                
                <div className={styles.sizeSelector}>
                  <div className={styles.sectionTitle}>
                    <h3>Select Size</h3>
                    <div ref={sizeErrorRef} className={styles.sizeError}>Please select a size</div>
                  </div>
                  <div className={styles.sizeOptions}>
                    {expectedSizes.map((size) => {
                      const isAvailable = currentProduct.sizes?.includes(size);
                      const isSelected = selectedSize[currentProduct.id] === size;
                      
                      return (
                        <button
                          key={size}
                          className={`${styles.sizeOption} 
                                    ${!isAvailable ? styles.unavailableSize : ''} 
                                    ${isSelected ? styles.selectedSize : ''}`}
                          onClick={() => isAvailable && handleSizeSelect(size, currentProduct.id)}
                          disabled={!isAvailable}
                        >
                          {size}
                        </button>
                      );
                    })}
                  </div>
                </div>
                
                <div className={styles.quantitySelector}>
                  <div className={styles.sectionTitle}>
                    <h3>Quantity</h3>
                  </div>
                  <div className={styles.quantityControls}>
                    <button 
                className={styles.quantityButton}
                      onClick={decrementQuantity}
                      disabled={quantity <= 1}
              >
                -
                    </button>
              <span className={styles.quantityValue}>{quantity}</span>
                    <button 
                className={styles.quantityButton}
                      onClick={incrementQuantity}
              >
                +
                    </button>
              </div>
            </div>

                <div className={styles.cartActions}>
                  {!hasAdded ? (
                    <button className={styles.addToCartButton} onClick={handleAddToCart}>
                      <FaShoppingBasket /> Add to Cart
                    </button>
                  ) : (
                    <>
                      <button className={styles.goToCartButton} onClick={handleGoToCart}>
                        Go to Cart
                      </button>
                      <button className={styles.removeButton} onClick={removeFromCart}>
                        Remove
                      </button>
                    </>
                  )}
                </div>
              </div>
              
              <div className={styles.productTabs}>
                <div className={styles.tabsHeader}>
                  <button 
                    className={`${styles.tabButton} ${activeTab === 'description' ? styles.activeTab : ''}`}
                    onClick={() => setActiveTab('description')}
                  >
                    Description
                  </button>
                  <button 
                    className={`${styles.tabButton} ${activeTab === 'details' ? styles.activeTab : ''}`}
                    onClick={() => setActiveTab('details')}
                  >
                    Details
                  </button>
                  <button 
                    className={`${styles.tabButton} ${activeTab === 'shipping' ? styles.activeTab : ''}`}
                    onClick={() => setActiveTab('shipping')}
                  >
                    Shipping
                  </button>
                </div>
                
                <div className={styles.tabContent}>
                  {activeTab === 'description' && (
                    <div className={styles.descriptionTab}>
                      <p>{currentProduct.description || "No description available."}</p>
                    </div>
                  )}
                  
                  {activeTab === 'details' && (
                    <div className={styles.detailsTab}>
                      <ul className={styles.productDetailsList}>
                        <li>Material: {currentProduct.material || "Not specified"}</li>
                        <li>Brand: {currentProduct.brand || "Not specified"}</li>
                        <li>Style: {currentProduct.style || "Not specified"}</li>
                        <li>Category: {typeof currentProduct.category === 'object' ? currentProduct.category.name : currentProduct.category || "Not specified"}</li>
                        <li>Care: {currentProduct.care || "See product label for care instructions"}</li>
                      </ul>
                    </div>
                  )}
                  
                  {activeTab === 'shipping' && (
                    <div className={styles.shippingTab}>
                      <p>Standard shipping: 3-5 business days</p>
                      <p>Express shipping: 1-2 business days (additional fee)</p>
                      <p>Nigeria: $5 shipping fee</p>
                      <p>All other countries: $10 shipping fee</p>
                      <p>20% discount on shipping for orders over $500</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          
          {/* Related Products Section */}
          <div className={styles.relatedProductsSection}>
            <h3 className={styles.relatedTitle}>You May Also Like</h3>

              {status === "loading" ? (
              <div className={styles.loadingSpinner}>
                <div className={styles.spinner}></div>
                <p>Loading related products...</p>
              </div>
              ) : shuffledRelatedProducts.length > 0 ? (
              <div className={styles.relatedProductsGrid}>
                {shuffledRelatedProducts.slice(0, 4).map((product) => (
                    <div
                      key={product._id}
                    className={styles.relatedProductCard}
                      onClick={() => handleProductChange(product)}
                    >
                    <div className={styles.relatedImageContainer}>
                      <img
                        src={product.image}
                        alt={product.name}
                        className={styles.relatedProductImage}
                      />
                    </div>
                    <div className={styles.relatedProductInfo}>
                      <h4 className={styles.relatedProductName}>{product.name}</h4>
                      <p className={styles.relatedProductPrice}>
                        ${new Intl.NumberFormat("en-US").format(product.price)}
                      </p>
                    </div>
                    </div>
                  ))}
                </div>
              ) : (
              <p className={styles.noRelatedProducts}>No related products found.</p>
              )}
            </div>
        </div>
      </motion.div>
    </div>
  );
};

export default ProductModal;
