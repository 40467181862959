import React, { useState } from "react";
import { FaWhatsapp, FaComments, FaTimes } from "react-icons/fa";
import "../styles/needHelp.css";

const NeedHelp = () => {
  const whatsappNumber = "+2348137816749";
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className={`help-widget ${isExpanded ? 'expanded' : ''}`}>
      {isExpanded ? (
        <div className="help-content">
          <div className="help-header">
            <h3>How can we help you?</h3>
            <button className="close-button" onClick={toggleExpand}>
              <FaTimes />
            </button>
          </div>
          <p className="help-message">
            Our customer support team is ready to assist you with any questions or concerns.
          </p>
          <a
            href={`https://wa.me/${whatsappNumber}`}
            target="_blank"
            rel="noopener noreferrer"
            className="whatsapp-button"
          >
            <FaWhatsapp /> Chat on WhatsApp
          </a>
          <div className="help-footer">
            <p>Available 24/7</p>
          </div>
        </div>
      ) : (
        <button className="help-trigger" onClick={toggleExpand}>
          <div className="help-icon">
            <FaComments />
          </div>
          <span>Need Help?</span>
        </button>
      )}
    </div>
  );
};

export default NeedHelp;
